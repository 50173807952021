.blueButton {
    width: 100%;
    height: 32px;
    color: var(--color-white);
    background: var(--color-blue);
    margin: 0 auto 10px auto;
}
.blueButton:hover {
    box-shadow: 1px 1px 5px rgba(12, 191, 231, .5);
}

.lightBlueButton {
    height: 32px;
    width: 100%;
    background-color: #D4F7FF;
    color: var(--color-ocean-blue)
}
.lightBlueButton:hover {
    box-shadow: 1px 1px 5px rgba(212, 247, 255, 0.5);
}
