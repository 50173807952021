.container {
    height: 40px;
    width: 100%;
    max-width: 400px;
    border-radius: var(--size-2xs);
    border: 1px solid var(--Slate-200);
    background: var(--Base-White);
    padding: var(--size-sm) var(--size-md);
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--Slate-950);
    transition: .3s ease-out;
}
.container:focus {
    border: 1px solid var(--Blue-600);
    box-shadow: 0 2px 4px var(--Blue-100);
}
.container[type='number']::-webkit-outer-spin-button,
.container[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.container[type='number'] {
    -moz-appearance: textfield;
}
.container::placeholder {
    color: var(--Slate-400);
}
.container.warning {
    border: 1px solid var(--Red-500);
    box-shadow: 0 2px 4px var(--Red-100);
}