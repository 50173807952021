.custom-date-picker-calendar-container {
    width: 330px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-white);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .15);
    border-radius: 2px;
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: var(--color-black);
    padding: 27px 20px;
    z-index: 100;
}
.custom-date-picker-calendar-container .nice-dates-navigation {
    margin: 0 auto;
    color: var(--color-black);
}
.custom-date-picker-calendar-container .nice-dates-navigation_previous:hover:before,
.custom-date-picker-calendar-container .nice-dates-navigation_next:hover:before {
    border-color: var(--color-black);
}
.custom-date-picker-calendar-container .nice-dates-navigation_next:before {
    margin-left: -1px;
}
.custom-date-picker-calendar-container .nice-dates-navigation_previous:before, .nice-dates-navigation_next:before {
    width: 8px;
    height: 8px;
    border-right: 2px solid var(--color-darkblue);
    border-top: 2px solid var(--color-darkblue);
}
.custom-date-picker-calendar-container .nice-dates-week-header {
    margin: 0 auto;
    color: var(--color-grey);
    box-shadow: unset;
}
.custom-date-picker-calendar-container .nice-dates-grid_container {
    margin: 0 auto;
}
.custom-date-picker-calendar-container .nice-dates-day_month {
    display: none;
}
.custom-date-picker-calendar-container .nice-dates-day {
    border: none;
    border-bottom: 1px solid white;
}
.custom-date-picker-calendar-container .nice-dates-day.-outside {
    color: var(--color-lightgrey);
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-middle:not(.-end):nth-child(7n):before {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-middle:nth-child(7n+1):before {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
.custom-date-picker-calendar-container .nice-dates-day:before {
    background-color: var(--color-darkblue);
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-start:before {
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-end:before {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-middle:before {
    background-color: rgba(20, 119, 248, .15);
}
.custom-date-picker-calendar-container .nice-dates-day.-selected:not(:hover) * {
    color: var(--color-white);
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-start:not(:hover) * {
    color: var(--color-white);
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-middle:not(:hover) * {
    color: var(--color-black);
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-end:not(:hover) * {
    color: var(--color-white);
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-start {
    background-color: rgba(20, 119, 248, .15);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-start:nth-child(7n) {
    border-radius: 50%;
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-end:nth-child(7n+1) {
    border-radius: 50%;
}
.custom-date-picker-calendar-container .nice-dates-day.-selected.-selected-end {
    background-color: rgba(20, 119, 248, .15);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.custom-date-picker-calendar-container .nice-dates-day:after {
    border: 2px solid var(--color-darkblue);
}
.custom-date-picker-calendar-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}
.custom-date-picker-calendar-footer .custom-date-picker-interval-container {
    display: flex;
    align-items: center;
    width: 170px;
    height: 35px;
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    border: 1px solid var(--select-border-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px;
}
.custom-date-picker-calendar-footer .custom-date-picker-interval-container svg {
    margin-left: auto;
}



