.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.container p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-black);
}
.header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 16px 0 16px;
    margin-bottom: 8px;
}
.header h6 {
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    text-transform: uppercase;
    margin-right: auto;
}
.header p {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-blue);
    text-transform: capitalize;
    margin: 0 5px;
    cursor: pointer;
    transition: .3s;
}
.header p:hover {
    color: var(--color-grey);
}
.elementListContainer {
    max-height: 170px;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
    padding: 4px 16px 25px 16px;
    margin: 0 2px 8px 0;
}
.elementListContainer::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}
.elementListContainer:hover::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}
.elementListContainer:hover::-webkit-scrollbar-thumb {
    background-color: var(--color-blue);
}
.elementListContainer::-webkit-scrollbar-track {
    background-color: transparent;
}
.elementListContainer::-webkit-scrollbar-thumb {
    background-color: var(--color-blue);
    border-radius: 16px;
}
.elementListContainer::-webkit-scrollbar-button {
    display: none;
}
.elementRowContainer {
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
}
.elementRowContainer p {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-darkgrey);
    cursor: pointer;
    transition: .3s;
}
.elementIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.elementIconContainer svg path {
    fill: var(--color-darkblue);
}
.elementRowContainer:hover p,
.elementRowContainer.selected p {
    color: var(--color-green);
}
.elementRowContainer:hover .elementIconContainer svg path,
.elementRowContainer.selected .elementIconContainer svg path {
    fill: var(--color-green);
}
.elementRowContainer:before {
    position: absolute;
    top: 50%;
    left: -16px;
    width: 4px;
    height: 28px;
    background: var(--color-green);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transform: translateY(-50%);
    transition: .3s ease;
    opacity: 0;
    content: ''
}
.elementRowContainer.selected:before {
    opacity: 1;
}
.loaderContainer {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.elementDefaultMessage {
    padding: 0 16px;
}