.container {
    display: inline-block;
    position: relative;
    width: 100%;
    min-height: 600px;
    height: 100%;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 110px;
    height: 110px;
    transform: translate(-50%, -50%);
}