.dashboardHeaderWrapper {
    max-height: 120px;
    padding: 22px 40px 0 0;
    background-color: var(--color-white);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 25px;
    width: 100%;
}
.dashboardHeaderTopSide {
    min-height: 74px;
    display: flex;
    align-items: center;
    padding-left: 7px;
    justify-content: space-between;
    gap: 20px
}
.dashboardHeaderBottomSide {
    min-height: 24px;
    display: flex;
}
.dashboardHeaderBottomSide.hideHavLinkSwitcher {
    padding-bottom: 24px;
}
.dashboardHeaderTitleContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    width: 100%;
}
.dashboardHeaderTitle,
.dashboardHeaderEditTitle,
.dashboardHeaderDescription,
.dashboardHeaderEditDescription {
    display: flex;
    align-items: center;
    transition: .3s;
}
.dashboardHeaderEditTitle input {
    min-height: 24px;
    width: 100%;
    max-width: 400px;
    height: 29px;
    border: none;
    border-bottom: 1px solid var(--select-border-color);
    box-sizing: border-box;
    border-radius: 0;
    padding: 0 7px 0 0;
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    color: var(--color-black-grey);
}
.dashboardHeaderEditTitle input:focus {
    border: none;
    border-bottom: 1px solid var(--color-green) !important;
}
.dashboardHeaderEditTitle input.error {
    border: none;
    border-bottom: 1px solid var(--color-red) !important;
}
.dashboardHeaderTitleName {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    text-align: left;
    color: var(--color-black);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.dashboardHeaderSaveButton {
    display: flex;
    margin: 0 10px;
    cursor: pointer;
}
.dashboardHeaderSaveButton svg,
.dashboardHeaderEditButton svg {
    fill: var(--color-grey);
    transition: .3s;
}
.dashboardHeaderSaveButton svg:hover,
.dashboardHeaderEditButton svg:hover {
    fill: var(--color-green);
}
.dashboardHeaderStatusContainer {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.dashboardHeaderStatus {
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    text-transform: uppercase;
}
.dashboardHeaderStatus.dashboardHeaderStatusWithLaunchButton {
    margin: 0 10px;
}

.dashboardHeaderStatus span {
    text-transform: capitalize;
    font-size: 13px;
    margin-left: 4px;
}
.dashboardHeaderButtonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.dashboardHeaderButtonsContainer button {
    padding: 0;
    margin: 10px;
}
.navLinkSwitcher {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom: 2px solid transparent;
    width: 88px;
    height: 25px;
    cursor: pointer;
    margin-bottom: -1px;
    background: transparent;
    text-decoration: none;
}
.navLinkSwitcherActiveTab {
    border-color: var(--color-blue);
}

.communicationHeaderWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    min-height: 76px;
    max-height: 76px;
    background-color: var(--color-white);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
    border-radius: 2px;
    padding: 18px 40px 18px 18px;
    margin-bottom: 25px;
}
.communicationHeaderTitleContainer {
    margin-right: 24px;
    width: 100%;
    max-width: 246px;
}
.communicationHeaderTitle {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 13px;
    letter-spacing: .18px;
    color: var(--color-black);
    margin-bottom: 13px;
}
.communicationHeaderStatusTitle {
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    text-transform: uppercase;
}
.communicationHeaderStatusName {
    font-size: 13px;
    text-transform: capitalize;
    margin: 0 3px;
}
.communicationHeaderStatisticContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}
.communicationHeaderStatisticItem:first-child {
    margin: 0 45px 0 0;
}
.communicationHeaderStatisticItem {
    margin: 0 45px;
}
.communicationHeaderStatisticItem:last-child {
    margin: 0 0 0 45px;
}
.communicationHeaderStatisticItem h6 {
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    text-transform: uppercase;
    margin-bottom: 10px;
}
.communicationHeaderStatisticItem p {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 13px;
    color: var(--color-darkgrey);
}
.communicationHeaderButtonsContainer {
    width: 100%;
    max-width: 270px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 55px 0 auto;
}
.communicationHeaderButtonsContainer .communicationHeaderLauncherButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 89px;
    height: 40px;
    background: transparent;
    border: 1px solid var(--color-green);
    color: var(--color-green);
    text-decoration: none;
    border-radius: 5px;
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    transition: .3s;
}
.communicationHeaderButtonsContainer .communicationHeaderLauncherButton:hover {
    background: var(--color-green);
    border: 1px solid var(--color-green);
    color: var(--color-white);
}
.communicationHeaderButtonsContainer .communicationHeaderLauncherButton:disabled {
    border: 1px solid var(--border-color);
    color: var(--border-color);
    background: var(--color-white);
}
.communicationHeaderButtonsContainer .communicationHeaderLauncherButton:disabled:hover {
    box-shadow: unset;
}

.sectionHeaderContainer {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    height: 76px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px 0 16px;
    background: var(--color-white);
}
.sectionHeaderTitle {
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 24px;
}
.sectionHeaderButtonsContainer {
    display: flex;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
}
button.dashboardHeaderButton {
    width: -moz-fit-content;
    width: fit-content;
    min-width: 90px;
    height: 40px;
    padding: 0 10px;
}
button.dashboardHeaderButton.dashboardHeaderButtonLarge {
    min-width: 185px;
}
.dashboardHeaderDescriptionText {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
    display: -webkit-box;
    padding-right: 4px;
}

.dashboardHeaderDescriptionText,
.dashboardHeaderEditDescription textarea {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.18px;
    color: var(--color-grey);
    max-height: 34px;
}
.dashboardHeaderEditDescription textarea {
    resize: none;
    width: 100%;
    border: none;
    overflow-x: hidden;
    border-bottom: 1px solid var(--select-border-color);
}

.dashboardHeaderEditDescription textarea:focus {
    border-bottom: 1px solid var(--color-green);
}

.integrationHeader,
.uploadCsvHeader {
    padding: 18px 50px 18px 16px;
    background: var(--color-white);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
}

.integrationHeaderTitle,
.uploadCsvHeaderTitle {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 18px;
    line-height: 13px;
    letter-spacing: 0.18px;
    margin-bottom: 10px;
}
.integrationHeaderSubTitle {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: var(--color-grey)
}
.integrationHeaderNavigateButton,
.uploadCsvHeaderButton {
    height: 40px;
    padding: 0 9px;
    width: unset;
    min-width: 89px;
}
.integrationHeaderButtons,
.uploadCsvHeaderButtons {
    display: flex;
    gap: 23px;
}

