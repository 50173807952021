.tagsInputContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 32px;
    min-height: 32px;
    width: 100%;
    border: 1px solid var(--select-border-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 3px 36px 2px 7px;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    color: var(--color-black-grey);
    margin-bottom: 5px;
    overflow-y: hidden;
    transition: .3s;
}
.tagsInputContainer.withoutErrorField {
    margin-bottom: 25px;
}
.tagsInputContainer.open {
    height: auto;
}
.tagsInputContainer:focus-within {
    border: 1px solid var(--color-darkblue) !important;
    box-shadow: 0 2px 4px rgba(20, 119, 248, .15) !important;
}
.tagsInputContainer.error {
    border: 1px solid #EB5757 !important;
    box-shadow: 0 2px 4px rgba(255, 102, 96, 0.15) !important;
}
.tagContainer {
    width: fit-content;
    display: flex;
    align-items: center;
    background: var(--color-transparent-green);
    color: var(--color-black);
    border-radius: 3px;
    margin: 2px 2px 3px 2px;
}
.tagContainer p  {
    padding: 2px 4px 2px 6px;
    cursor: pointer;
}
.removeTagButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 22px;
    background: var(--color-darkgreen);
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-left: 3px;
    padding: 5px;
}
.removeTagButton svg {
    width: 10px;
    height: 10px;
}
.tagInput {
    width: 10%;
    height: 20px;
    flex-grow: 1;
    background: transparent;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: none;
    margin: 2px;
    padding-left: 2px;
    border: 0 !important;
}
.tagInput.tagInputEmpty {
    width: 100%
}
.tagInputOpenButton {
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
