.radioboxItemContainer {
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
}
.radioboxItem:checked,
.radioboxItem:not(:checked) {
    display: none;
}
.radioboxItem:checked + label,
.radioboxItem:not(:checked) + label
{
    position: relative;
    padding-left: 21px;
    cursor: pointer;
    display: inline-block;
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-black);
    margin: 0;
}
.radioboxItem:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid var(--border-color);
    border-radius: 100%;
    background: var(--color-white);
}
.radioboxItem:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-blue);
    border-radius: 100%;
    background: var(--color-blue);
    box-shadow: 0 0 5px 1px rgba(12, 191, 231, 0.3);
}
.radioboxItem:checked + label:after,
.radioboxItem:not(:checked) + label:after {
    content: '';
    width: 6px;
    height: 6px;
    background: var(--color-white);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.radioboxItem:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radioboxItem:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
