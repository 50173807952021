.scenarioSetupLayout {
    padding: 30px 0 0 32px;
}
.scenariosWrapperContainer {
    position: relative;
    width: 100%;
    height: calc(100vh - 240px);
    min-height: 700px;
    background-color: var(--color-white);
    background-image: url('../../assets/img/bg.svg');
    background-repeat: repeat;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
.scenariosWrapperContainer .scenariosWrapperWorkplace {
    height: 2000px;
    width: 2000px;
    padding: 5px;
}
