.pop-up-cellsbody.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.pop-up-overlay {
    background: rgba(0, 0, 0, 0.3);
}
.pop-up-cells,
.pop-up {
    width: 100%;
    max-width: 284px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    border-radius: 2px;
    z-index: 10;
    padding: 25px;
}
.pop-up.error {
    border: 1px solid var(--color-red);
}
.pop-up h3 {
    font-family: "ProximaNova-Bold", sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-black);
    text-align: center;
    margin-bottom: 6px;
}
.pop-up p {
    font-family: "ProximaNova-Regular", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black);
    text-align: center;
    margin-bottom: 25px;
}
.form-alerter-pop-up {
    max-width: 284px;
    padding: 25px;
}
.select-alerter-pop-up {
    max-width: 350px;
}
.select-alerter-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.buttons-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
}
.pop-up button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 120px;
    height: 36px;
    margin: 0 auto;
    background: var(--color-green);
    color: var(--color-white);
    border: 1px solid transparent;
    border-radius: 5px;
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    transition: .3s;
    cursor: pointer;
}
.pop-up button.large {
    max-width: 224px;
}
.pop-up button.error {
    background: var(--color-red);
}
.pop-up button.error:hover {
    background: var(--color-lightred);
    border: 1px solid var(--color-lightred);
    box-shadow: 1px 1px 5px rgba(180, 1, 1, 0.5);
}
.pop-up button:hover {
    border: 1px solid var(--color-darkgreen);
    background: var(--color-darkgreen);
    box-shadow: 1px 1px 5px rgba(1, 180, 147, 0.5);
}
.pop-up button.transparent-button {
    background: var(--color-white);
    color: var(--color-green);
    border: 1px solid transparent;
    box-shadow: unset;
}
.pop-up button.transparent-button:hover {
    background: var(--color-white);
    color: var(--color-darkgreen);
    box-shadow: unset;
}

.picture-alerter-pop-up {
    max-width: 520px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 16px;
}
.picture-alerter-pop-up h3,
.picture-alerter-pop-up p {
    text-align: left;
}
.picture-alerter-pop-up h3 {
    font-size: 18px;
    margin-bottom: 5px;
}
.picture-alerter-pop-up p {
    margin-bottom: unset;
}
.picture-alerter-pop-up button {
    margin: auto 0 0 auto;
}
.picture-alerter-pop-up-container {
    display: flex;
    flex-direction: column;
}
.segment-alerter-pop-up-image {
    width: 155px;
    height: 155px;
    background-image: url("assets/img/SegmentSettings.png");
    flex-shrink: 0;
}
.warning-alerter-pop-up-image {
    width: 155px;
    height: 155px;
    background-image: url("assets/img/WarningAlert.png");
    flex-shrink: 0;
}
.picture-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}
.picture-buttons-container button {
    margin-left: 10px;
}
.pop-up-cells {
    width: 304px;
    padding: 27px 24px;
}
.pop-up-cells p {
    text-align: center;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 13px;
    letter-spacing: 0.18px;
}
