.selectContentLayout {
    height: calc(100vh - 203px);
    min-height: 700px;
    width: 100%;
    padding: 14px 20px 24px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    border-radius: 2px 0 0 0;
    background: var(--color-white);
    overflow-x: hidden;
}
.selectContentLayoutTitle {
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    color: var(--color-grey);
    margin-bottom: 16px;
}
.selectContentItems {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
