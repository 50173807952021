.sideBarTitle {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-black);
    margin-bottom: 25px;
}

.sideBarSubTitle {
    font-family: 'ProximaNova-Semibold', sans-serif;
    color: var(--color-grey);
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    margin: 10px 0;
}

.sidebarGeneralButtons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.sidebarGeneralCreatorCard {
    padding: 10px 8px;
    border: 1px solid var(--select-border-color);
    box-sizing: border-box;
    border-radius: 4px;
    height: 86px;
    cursor: pointer;
    text-align: left;
    background-color: transparent;
}
.sidebarGeneralCreatorCard h3 {
    font-family: 'ProximaNova-Semibold', sans-serif;
    color: var(--color-grey);
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    margin: 0 0 6px;
}
.sidebarGeneralCreatorCard p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
}

.sidebarGeneralCreatorCardIsChecked {
    border: 1px solid var(--color-blue);
}

.sidebarGeneralCreatorCardContainer {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 25px;
}

.sidebarGeneralContainer {
    padding: 25px 15px 25px 15px;
    font-family: 'ProximaNova-Regular', sans-serif;
    height: 100%;
    min-height: 560px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
}
.sidebarGeneralContainer > button {
    flex-shrink: 0;
    width: 100%;
}
