.chainInfoWrapper {
    min-width: 120px;
    max-width: 140px;
    position: absolute;
    left: 0;
    top: -5px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    box-shadow: 2px 4px 15px rgba(19, 17, 80, .25);
    border-radius: 3px;
    background: var(--color-white);
    z-index: 100;
}
.chainInfoWrapper button {
    display: flex;
    align-items: center;
    background: transparent;
    outline: none;
    border: none;
    color: var(--color-black);
    padding: 7px 20px;
    cursor: pointer;
    transition: .3s;
}
.chainInfoWrapper a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-black);
    padding: 7px 20px;
    margin: 0;
    transition: .3s;
}
.chainInfoWrapper button svg,
.chainInfoWrapper a svg {
    transition: .2s;
    cursor: pointer;
    color: var(--border-color);
    margin-right: 8px;
}
.chainInfoWrapper button:hover svg,
.chainInfoWrapper a:hover svg {
    color: var(--color-green);
}
.chainInfoWrapper button:hover,
.chainInfoWrapper a:hover {
    color: var(--color-green);
    background: rgba(239, 237, 255, .5);
}

.ellipsisIcon {
    position: relative;
    fill: var(--border-color);
    transition: .2s;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.activeIcon svg {
    fill: var(--color-blue);
}
