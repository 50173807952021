.container h3 {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-black);
    text-align: center;
    margin-bottom: 6px;
}
.container p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black);
    text-align: center;
    margin-bottom: 25px;
}
.buttonsContainer {
    display: flex;
    gap: 8px;
    margin-top: 19px;
}
.buttonsContainer .button {
    width: 145px;
}