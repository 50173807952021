.defaultButton {
    min-width: 60px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
    text-decoration: none;
}
.defaultButton.disabledButton {
    background: #F2F2F2;
    color: #BDBDBD;
}
.defaultButton.disabledButton:hover {
    background: #F2F2F2;
    border: 1px solid #BDBDBD;
}
