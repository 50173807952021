.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .3);
}
.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 1050;
}
.wrapper {
    overflow: auto;
}
/* width */
.wrapper::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.wrapper:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.wrapper:hover::-webkit-scrollbar-thumb {
    background-color: transparent;
}
/* Track */
.wrapper::-webkit-scrollbar-track {
    background-color: transparent;
}
/* Handle */
.wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 16px;
}
.wrapper::-webkit-scrollbar-button {
    display: none;
}
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background: var(--color-white);
    max-width: 430px;
    padding: 55px 64px;
    z-index: 100;
}