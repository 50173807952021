.title {
    display: flex;
    flex-direction: column;
    gap: var(--size-md);
    align-items: center;
}
.description {
    display: flex;
    align-items: center;
    gap: var(--size-2xs);
}
.form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
}
.requirements {
    display: flex;
    flex-direction: column;
    gap: var(--size-2xs);
}
.row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--size-sm);
}