.scenariosContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
}
.scenariosWorkplaceOverlay {
    background-color: unset !important;
}
.scenariosWorkplaceSidebar {
    width: 312px;
    background: var(--color-white);
    box-shadow: 0 4px 4px 3px rgba(0, 0, 0, 0.1);
    border-radius: 2px 2px 0 0;
}

.scenariosContainer .scenariosProcessItem {
    display: inline-block;
    position: absolute;
}
.arrow {
    pointer-events: none;
}

.arrow__path {
    stroke: #9088DF;
    fill: transparent;
    stroke-width: 3;
    z-index: 10;
}
