.greenButton {
    width: 130px;
    height: 40px;
    color: var(--color-white);
    background: var(--color-green);
}
.greenButton:hover {
    background: var(--color-green-3);
}
.greenButton:active {
    background: var(--color-green-2);
}
.lightGreenButton {
    width: 130px;
    height: 40px;
    color: var(--color-green);
    background: var(--color-transparent-green);
    border: 1px solid var(--color-transparent-green);
}
.lightGreenButton:hover {
    border: 1px solid var(--color-green);
}
.lightGreenButton:active {
    background: var(--color-green);
    color: var(--color-white);
    border: 1px solid var(--color-green);
}