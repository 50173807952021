.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-lg);
}
.code {
    display: flex;
    align-items: center;
    gap: var(--size-sm);
}
.warning {
    text-align: center;
}