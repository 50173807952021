.container {
    position: relative;
    max-height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.container p {
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-green);
    text-shadow: 0 0 1px rgba(0, 0, 0, .15);
}
.roundButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    background-color: var(--color-green);
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, .15));
    cursor: pointer;
    transition: .3s;
    z-index: 1;
}
.roundButton:hover {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, .3));
}

.fixed {
    width: 80px;
    position: fixed;
    right: 100px;
    bottom: 30px;
}
.crookedArrow {
    position: absolute;
    top: 12px;
    right: -25px;
    width: 30px;
    height: 45px;
    background-image: url('../../../assets/img/Crooked-arrow.svg');
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
}
.selectOptionContainer {
    position: absolute;
    top: -80px;
    left: 50%;
    width: 220px;
    background: #FFFFFF;
    border: 1px solid #00C6A2;
    border-radius: 5px;
    transform: translateX(-50%);
}
.selectOptionContainer .option {
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-black-grey);
    line-height: 13px;
    transition: .3s;
    cursor: pointer;
}
.selectOptionContainer .option:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 12px;
}
.selectOptionContainer .option:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 0;
    padding-bottom: 12px;
}
.selectOptionContainer .option:hover {
    background: var(--row-color);
}
.selectOptionContainer .option {
    border-bottom: 1px solid var(--row-color);
}
