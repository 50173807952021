.dropdownLabel {
    display: inline-block;
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin-bottom: 10px;
}
.dropdownContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 280px;
    min-height: 32px;
    border: 1px solid var(--select-border-color);
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
}
.dropdownContainer.opened {
    border: 1px solid var(--color-darkblue);
    box-shadow: 0 2px 4px rgba(20, 119, 248, .15);
}
.dropdownContainer.hasError {
    border: 1px solid #EB5757;
    box-shadow: 0 2px 4px rgba(255, 102, 96, .15);
}
.dropdownContainer.disabled {
    background: #FAFAFA;
    margin-bottom: 25px;
    cursor: not-allowed;
}
.dropdownContainer.emptyOptionsList {
    cursor: not-allowed;
}
.dropdownChevron {
    position: absolute;
    top: 50%;
    right: 7px;
    width: 7px;
    height: 4px;
    background-image: url('../../assets/img/Arrow_dropdown.svg');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    transition: .3s;
}
.dropdownChevron.opened {
    transform: translateY(-50%) rotate(180deg);
}
.dropdownHeader {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: var(--color-black);
    padding: 0 16px 0 8px;
}
.dropdownListContainer {
    position: absolute;
    top: 32px;
    left: -1px;
    width: 100%;
    max-width: 280px;
    max-height: 500px;
    z-index: 10;
    overflow-y: scroll;
}
/* width */
.dropdownListContainer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.dropdownListContainer:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.dropdownListContainer:hover::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
}
/* Track */
.dropdownListContainer::-webkit-scrollbar-track {
    background-color: transparent;
}
/* Handle */
.dropdownListContainer::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
    border-radius: 16px;
}
.dropdownListContainer::-webkit-scrollbar-button {
    display: none;
}
.dropdownListContainer ul {
    background: var(--color-white);
    border: 1px solid var(--select-border-color);
    border-radius: 4px;
    box-sizing: border-box;
    color: #3faffa;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 10px 0;
}
.dropdownListContainer ul li {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: var(--color-black);
    list-style: none;
    padding: 0 16px 0 8px;
    margin-bottom: 6px;
    cursor: pointer;
}
.dropdownListContainer ul li:last-child {
    margin-bottom: 0;
}
.dropdownListContainer ul li:hover {
    color: var(--color-blue);
}
