.container {
    height: 40px;
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0;
    background: var(--Mint-500);
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: var(--Base-White);
    transition: .3s ease-out;
    cursor: pointer;
}
.container:hover {
    background: var(--Mint-600);
}
.container:active {
    background: var(--Mint-500);
}
.container.disabled {
    background: var(--Slate-200);
    cursor: no-drop;
}