.deleteButton {
    width: 16px;
    height: 18px;
    cursor: pointer;
}
.deleteButton svg {
    fill: var(--border-color);
    transition: .3s;
}
.deleteButton:hover svg {
    fill: var(--color-green) !important;
}
.deleteButton.withName {
    width: fit-content;
    display: flex;
    gap: 4px;
    align-items: center;

}
.deleteButton.withName p {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--border-color);
    transition: .3s;
}
.deleteButton.withName:hover p {
    color: var(--color-green);
}
