.templatePreview {
    margin: 15px auto 40px;
    padding: 13px 10px 10px;
    cursor: pointer;
    border: 1px solid var(--select-border-color);
    width: 235px;
}
.webPushTemplatePreview {
    padding: 0;
    border: unset;
}
.templatePreviewTitle {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    color: var(--color-black);
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 3px 0;
}
.templatePreview .templatePreviewImg {
    height: 210px;
    padding: 5px;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    -webkit-background-size: contain;
    background-size: cover;
    background-position: center center;
}

.templatePreviewDefault {
    width: 188px;
    height: 188px;
    background-image: url("../../../../../assets/img/DefaultTemplate.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 15px auto 5px;
    cursor: pointer;
    flex-shrink: 0;
}
.templatePreviewDefaultErrorMessageField {
    text-align: center;
    margin-bottom: 30px !important;
}
.previewActionViberTemplateWrapper {
    min-height: 250px;
    width: 235px;
    max-height: 250px;
    padding: 10px 15px 28px;
    margin: 10px auto;
    background: var(--row-color);
    border-radius: 4px;
    font-size: 18px;
    line-height: 24px;
    overflow-y: scroll;
    cursor: pointer;
}
/* width */
.previewActionViberTemplateWrapper::-webkit-scrollbar {
    display: none;
}
.previewActionViberTemplateWrapper:hover::-webkit-scrollbar {
    display: none;
}
.previewActionViberTemplateWrapper:hover::-webkit-scrollbar-thumb {
    display: none;
}
/* Track */
.previewActionViberTemplateWrapper::-webkit-scrollbar-track {
    display: none;
}
/* Handle */
.previewActionViberTemplateWrapper::-webkit-scrollbar-thumb {
    display: none;
}
.previewActionViberTemplateWrapper::-webkit-scrollbar-button {
    display: none;
}
.previewActionViberTemplateBody {
    word-break: break-word;
    white-space: pre-line;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 14px;
    line-height: 24px;
}
.previewActionViberTemplateBody img {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: 0 5px;
    position: relative;
    top: 5px;
}
.previewActionViberTemplateImage {
    max-width: 100%;
    display: block;
    margin: 15px auto;
}
.previewActionViberTemplateButton {
    background: var(--color-violet);
    border-radius: 20px;
    padding: 10px 20px;
    margin-top: 25px;
}
.previewActionViberTemplateButton p {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--color-white)
}
.previewActionViberTemplateTitle {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    color: var(--color-black);
    text-align: center;
    margin: 5px 0 25px 0;
}
.previewActionSmsTemplate {
    min-height: 130px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.previewActionSmsTemplateMsg {
    display: flex;
    height: 210px;
    width: 100%;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 14px;
    overflow-y: scroll;
}
.previewActionSmsTemplateMsg p {
    width: fit-content;
    margin: auto;
    padding: 5px 8px;
    background-color: var(--row-color);
    border-radius: 8px 8px 8px 0;
}
/* width */
.previewActionSmsTemplateMsg::-webkit-scrollbar {
    display: none;
}
.previewActionSmsTemplateMsg:hover::-webkit-scrollbar {
    display: none;
}
.previewActionSmsTemplateMsg:hover::-webkit-scrollbar-thumb {
    display: none;
}
/* Track */
.previewActionSmsTemplateMsg::-webkit-scrollbar-track {
    display: none;
}
/* Handle */
.previewActionSmsTemplateMsg::-webkit-scrollbar-thumb {
    display: none;
}
.previewActionSmsTemplateMsg::-webkit-scrollbar-button {
    display: none;
}
.previewActionMobilePush {
    height: 160px;
    background-image: url("../../../../../assets/img/mobilePushImages/previewTemplateIphone.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 80px 0 20px;
    margin-bottom: 10px;
}
.previewActionWebPush {
    height: 163px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.websiteBuilderCardContainer {
    height: 210px;
    padding: 10px;
}
.websiteBuilderCard {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
