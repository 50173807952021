.tabButtonsContainer {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}
.tabButtonsContainer button {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom: 2px solid var(--border-color);
    width: 100%;
    height: 25px;
    cursor: pointer;
    margin-bottom: -1px;
    background: transparent;
    text-transform: uppercase;
}
.tabButtonsContainer button.active {
    border-bottom: 2px solid var(--color-blue);
    color: var(--color-blue);
}
