.dateInputViewerLabel {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin-bottom: 10px;
}
.sidebarDateViewer {
    display: flex;
    align-items: center;
    width: 170px;
    height: 35px;
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    border: 1px solid var(--select-border-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px;
    color: var(--color-black);
    cursor: pointer;
    margin-bottom: 5px;
}
.sidebarDateViewer.singleDate {
    width: 152px;
}
.sidebarDateViewer.error {
    border: 1px solid #EB5757 !important;
    box-shadow: 0 2px 4px rgba(255, 102, 96, 0.15) !important;
}
.sidebarDateViewer svg {
    margin-left: auto;
    fill: var(--color-darkblue);
}

.dataViewerWrapper {
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataViewerWrapper svg {
    fill: var(--color-grey);
    margin-left: 5px;
}
