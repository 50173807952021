.container {
    width: 100%;
    max-width: unset;
    background: var(--Slate-100);
    box-shadow: unset;
    padding: var(--size-lg) 24px;
}
.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--size-xl);
}
.column {
    display: flex;
    flex-direction: column;
    gap: var(--size-sm);
}
.price {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}
.row {
    display: flex;
    align-items: center;
    gap: 24px;
}
.button {
    width: fit-content;
    padding: 0 24px;
}