.container {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: var(--Slate-950);
}
.container {

}
.container span {
    color: var(--Slate-400);
}