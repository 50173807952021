.switch {
    position: relative;
    display: inline-block;
    width: 27px;
    height: 17px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--row-color);
    border: 1px solid var(--switch-border-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 1px;
    bottom: 1px;
    background-color: var(--color-white);
    -webkit-transition: .3s;
    transition: .3s;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .25);
}

.switch input:checked + .slider {
    background: var(--color-green);
    border: 1px solid var(--color-green);
}

.switch input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
