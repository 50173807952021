.header {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 64px;
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .05);
    padding: 3px 80px 3px 30px;
}
.headerIconsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    z-index: 1;
}
.headerIconsContainer a {
    margin-left: 20px;
}
.headerIconsContainer .icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0 10px;
    border: none;
    background: transparent;
}
.headerIconsContainer .logOutContainer {
    display: flex;
    align-items: center;
}
.headerIconsContainer .logOutContainer .username {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: var(--color-grey);
}
.icon.logOutIcon {
    background-image: url(../../assets/img/LogOutIcon.svg);
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
}
.icon.chatIcon {
    background-image: url(../../assets/img/chat_icon.svg);
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
}
.icon.infoIcon {
    background-image: url(../../assets/img/info_icon.svg);
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
}
.breadcrumbs {
    display: flex;
    align-items: center;
    margin-right: auto;
}
.breadcrumbContainer:before {
    display: inline-block;
    width: 3px;
    height: 6px;
    background-image: url(../../assets/img/Arrow.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding-right: 5px;
    padding-left: 5px;
    color: var(--color-grey);
    content: "";
}
.breadcrumbs .breadcrumbContainer:first-child:before {
    display: none;
}
.breadcrumb {
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 12px;
    line-height: 15px;
    text-decoration: none;
    cursor: pointer;
    color: var(--color-grey);
    display: inline-block;
}
.breadcrumb:hover {
    color: var(--color-darkgrey);
}
.selectLanguageContainer {
    position: relative;
}
.selectedFlagContainer {
    cursor: pointer;
}
.selectLanguageDropdown {
    position: absolute;
    right: -20px;
    top: 30px;
    width: 170px;
    min-height: 64px;
    background: var(--color-white);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.selectLanguageDropdownItem {
    height: 50%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: .3s;
}
.selectLanguageDropdownItem.selected {
    background: var(--color-thinblue);
}
.selectLanguageDropdownItem p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: var(--color-black);
    margin: 0 10px;
}
.selectedFlag {
    display: none;
}
.selectedFlag.selectedFlagActive {
    display: block;
}
.selectedArrow {
    margin: 0 6px;
    opacity: 0;
    transition: .2s;
}
.selectedArrow.show {
    opacity: 1;
}
.sandboxModeLabel {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 4px 6px;
    background: var(--color-blue);
    color: var(--color-white);
    border-radius: 4px;
    cursor: default;
}
.bookCallRedirectButton {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 32px;
    background: var(--color-green);
    border-radius: 4px;
    padding: 8px;
    text-decoration: none;
}
.bookCallRedirectButton p {
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-white);
}
.launchScenariosMessage {
    display: flex;
    align-items: center;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-darkgrey-two);
    margin-left: auto;
}
.launchScenariosMessage p {
    margin: 0 5px 0 10px;
}
.launchScenariosMessage .arrow {
    margin-top: 30px;
}
.launchScenariosMessage .button {
    width: 134px;
    max-height: 30px !important;
    min-height: unset;
    font-size: 13px;
    margin: 0 10px;
}

@media screen and (max-width: 1920px) {
    .headerIconsContainer {
        margin-left: auto;
    }
}
@media screen and (max-width: 1440px) {
    .headerIconsContainer {
        margin-left: auto;
    }
    .launchScenariosMessage {
        font-size: 16px;
        line-height: 22px;
    }
}
@media (max-width: 1365px) {
    .launchScenariosMessage {
        font-size: 13px;
        line-height: 18px;
    }
    .breadcrumbContainer {
        display: flex;
        align-items: center;
        height: 15px;
    }
    .breadcrumb {
        height: 15px;
    }
    .breadcrumb span {
        display: inline-block;
        width: 100%;
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
