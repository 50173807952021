.container {
    font-family: 'ProximaNova-Regular', sans-serif;
    padding: 21px 16px;
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    letter-spacing: 0.18px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
}

.date {
    color: var(--border-color);
    margin-bottom: 10px;
    font-size: 13px;
}
.value {
    color: var(--color-darkgrey-two);
    font-size: 12px;
}

.title {
    color: var(--color-grey);
}

.valueContainer {
    display: flex;
    align-items: center;
}
.valueContainer + .valueContainer {
    margin-top: 12px;
}
.valueContainer span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.valueField {
    display: flex;
}
.valueField.columnValueBlock {

    flex-direction: column;
}
.valueField > p + p {
    margin: 0 0 0 6px;
}
.valueField.columnValueBlock > p + p {
    margin: 4px 0 0 0;
}
