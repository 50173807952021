.input {
    min-height: 32px;
    width: 100%;
    height: 32px;
    border: 1px solid var(--select-border-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 3px 5px 3px 7px;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: var(--color-black-grey);
    margin-bottom: 5px;
    transition: .1s;
}
.input:focus {
    border: 1px solid var(--color-darkblue) !important;
    box-shadow: 0 2px 4px rgba(20, 119, 248, .15) !important;
}
.input[type='number']::-webkit-outer-spin-button,
.input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input[type='number'] {
    -moz-appearance: textfield;
}
.input.error {
    border: 1px solid #EB5757 !important;
    box-shadow: 0 2px 4px rgba(255, 102, 96, .15) !important;
}
.inputWithoutErrorField {
    margin-bottom: 25px;
}
.inputFieldWithIcon {
    padding-left: 32px;
    height: 36px;
}
.inputFieldWithIconContainer {
    position: relative;
}
.inputFieldWithIconContainer svg {
    position: absolute;
    left: 10px;
    top: 8px;
}

