.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 16px;
    border-radius: 100px;
    outline: none;
    cursor: pointer;
    transition: background 0.3s ease;
}

.slider::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background: var(--Mint-500);
    border-radius: 50%;
    border: 2px solid var(--Base-White);
    box-shadow: var(--shadow-lg);
    transition: background-color .3s ease;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    position: relative;
    width: 40px;
    height: 40px;
    background: var(--Mint-500);
    border-radius: 50%;
    border: 2px solid var(--Base-White);
    box-shadow: var(--shadow-lg);
    cursor: pointer;
    transition: background-color 0.3s ease;
}
