.container {
    position: relative;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--Slate-950);
}
.required:after {
    color: var(--Red-500);
    margin-left: var(--size-xs);
    content: '*'
}