.errorMessage {
    min-height: 12px;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    color: var(--color-red);
    margin-bottom: 8px;
    opacity: 0;
    transition: .2s;
}
.errorMessage.activeMessage {
    opacity: 1 !important;
    margin-bottom: 8px;
}
