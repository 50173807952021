.webPushBodyContainer {
    width: 100%;
    background: var(--color-white);
    box-shadow: 0 5px 5px 1px rgba(0, 0, 0, .15);
}
.webPushBodyTop > span {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
}
.webPushBodyTop {
    padding: 2px 4px 10px;
    display: flex;
}
.webPushBodyBottom {
    padding: 5px;
    border-top: 1px solid var(--select-border-color);
}
.webPushBodyBottom p {
    font-size: 8px;
}
.webPushBodyIcons {
    display: flex;
    align-items: center;
    gap: 2px;
}

.webPushBodyPreviewTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 4px;
}
.webPushBodyPreview {
    flex: auto;
}
.webPushBodyMsg {
    margin-bottom: 3px;
    font-size: 7px;
    line-height: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
}

.webPushBodyLink {
    color: var(--color-grey);
    margin: 0 !important;
    font-size: 8px;
    padding-right: 8px;
}
.settingsWindowsIcon,
.closeWindowsIcon {
    background-size: contain;
}

.settingsWindowsIcon {
    background-image: url("../../../../assets/img/webPushImages/settingsWindowsIcon.svg");
    width: 8px;
    height: 8px;
}
.closeWindowsIcon {
    background-image: url("../../../../assets/img/webPushImages/closeWindowsIcon.svg");
    width: 8px;
    height: 8px;
}
