.creativeCard {
    position: relative;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 14px;
    border: 2px solid var(--select-border-color);
    border-radius: 5px;
    box-sizing: border-box;
    width: 256px;
    max-height: 281px;
    transition: .3s;
}
.cardTypeLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    background: green;
    border-bottom-right-radius: 100%;
    border-top-left-radius: 3px;
    background: var(--color-white);
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    cursor: pointer;
}
.cardTypeLabel svg {
    position: absolute;
    top: 7px;
    left: 8px;
    width: 16px;
    height: 16px;
}
.cardTypeLabel svg path {
    transition: .3s;
    fill: var(--color-darkgrey-two);
}
.creativeCardChecked {
    border: 2px solid var(--color-blue);
}
.creativeCardPreview {
    height: 194px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.creativeCard:hover {
    border: 2px solid var(--color-green);
}
.creativeCardFooter {
    position: relative;
    height: 72px;
    border-top: 1px solid var(--select-border-color);
    padding: 12px 10px 10px 16px;
    transition: .3s;
}
.creativeCardFooter.withoutUsage {
    height: 47px;
    padding: 8px 10px;
}
.creativeCardFooter .creativeCardFooterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.creativeCardFooter .creativeCardFooterUsage {
    color: var(--border-color);
}
.creativeCard:hover .creativeCardFooter {
    border-top: 1px solid var(--color-green);
    background: var(--color-green);
}
.creativeCardFooter p,
.creativeCardFooter p.name {
    width: 80%;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: .3s;
}
.creativeCardFooter p.name {
    cursor: pointer;
}
.creativeCardFooter p.name::after {
    position: absolute;
    top: -10px;
    left: 0;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    background: var(--color-black);
    color: var(--color-white);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    transition: .3s;
    z-index: -1;
    content: attr(data-title);
    opacity: 0;
}
.creativeCardFooter p.name:hover::after {
    z-index: 1;
    opacity: 1;
}
.creativeCard:hover .creativeCardFooter p {
    color: var(--color-white);
}
.creativeCardFooterIcons {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    margin-left: 5px;
}
.creativeCardFooterIcons > span {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 50%;
    transition: .3s;
    cursor: pointer;
}
.creativeCardFooterIcons > span:hover {
    box-shadow: 2px 4px 15px rgba(19, 17, 80, .25);
    background: rgba(227, 255, 250, .5);
}
.creativeCardFooterIcons svg {
    fill: var(--color-green);
    transition: .2s;
    cursor: pointer;
}
.creativeCard:hover .creativeCardFooter svg {
    fill: var(--color-white);
}
.creativeCardFooterIcons > span:hover svg {
    fill: var(--color-white);
}
/* Viber */
.viberCard {
    padding-top: 10px;
}
.viberCard .creativeCardPreview {
    height: 184px;
}
.viberCard .defaultCardPreview {
    margin: 10px auto 20px auto;
}
/* Sms */
.smsCard {
    height: 210px;
    margin: 0 14px;
    display: flex;
    overflow-y: scroll;
    padding: 10px 0;
}

.smsCard p {
    margin: auto;
    padding: 5px 8px;
    background-color: var(--row-color);
    border-radius: 8px 8px 8px 0;
}

/* Email */
.emailCard {
    height: 100%;
    margin: 0 14px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

/*MobilePush*/
.mobilePushCard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 194px;
    cursor: pointer;
}
/*WebPush*/
.webPushCard {
    height: 194px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    cursor: pointer;
}
.websiteBuilderCardContainer {
    height: 210px;
    padding: 20px 14px;
}
.websiteBuilderCard {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.defaultCardPreview {
    height: 100%;
    width: 100%;
    max-width: 175px;
    background-image: url('../../assets/img/DefaultTemplate.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px auto;
}
.previewTemplateViberCardWrapper {
    width: 90%;
    height: 210px;
    margin: auto;
    padding: 10px 15px 28px;
    background: var(--row-color);
    border-radius: 4px;
    font-size: 18px;
    line-height: 24px;
    overflow-y: scroll;
}
/* width */
.previewTemplateViberCardWrapper::-webkit-scrollbar,
.smsCard::-webkit-scrollbar {
    display: none;
}
.previewTemplateViberCardWrapper:hover::-webkit-scrollbar,
.smsCard:hover::-webkit-scrollbar {
    display: none;
}
.previewTemplateViberCardWrapper:hover::-webkit-scrollbar-thumb,
.smsCard:hover::-webkit-scrollbar-thumb {
    display: none;
}
/* Track */
.previewTemplateViberCardWrapper::-webkit-scrollbar-track,
.smsCard::-webkit-scrollbar-track {
    display: none;
}
/* Handle */
.previewTemplateViberCardWrapper::-webkit-scrollbar-thumb,
.smsCard::-webkit-scrollbar-thumb {
    display: none;
}
.previewTemplateViberCardWrapper::-webkit-scrollbar-button,
.smsCard::-webkit-scrollbar-button {
    display: none;
}
.previewTemplateViberCard {
    word-break: break-word;
    white-space: pre-line;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 18px;
    line-height: 24px;
}
.previewTemplateViberCard img {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: 0 5px;
    position: relative;
    top: 5px;
}
.previewTemplateViberCardImage {
    max-width: 100%;
    display: block;
    margin: 15px auto;
}
.previewTemplateViberCardButton {
    background: var(--color-violet);
    border-radius: 20px;
    padding: 10px 20px;
    margin-top: 25px;
}
.previewTemplateViberCardButton p {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--color-white)
}

.csvUploadCardContainer {
    width: 264px;
    height: 280px;
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    border-radius: 2px;
    padding: 0 20px;
}
.csvUploadCardTitle {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 13px;
    text-align: center;
    padding: 12px 0;
}
.csvUploadCardButton {
    margin: 18px auto;
}
@media screen and (min-width: 1920px) {
    .csvUploadCardContainer {
        width: 343px;
        height: 364px;
    }
    .csvUploadCardTitle {
        font-size: 21px;
        padding: 16px 0;
    }
    .csvUploadCardContainer .csvUploadCardButton {
        width: 100%;
        max-width: unset;
        height: 42px;
    }
}

