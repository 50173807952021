.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 32px 25px 15px;
    overflow-y: scroll !important;
    background: var(--color-white);
}
.actionSidebarForm {
    padding: 25px 15px 25px 15px;
}
