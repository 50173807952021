.container {
    display: flex;
    align-items: center;
    gap: var(--size-sm);
}
.clock {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotateClockwise 1.5s ease-in-out infinite;
}
@keyframes rotateClockwise {
    from {
        transform: rotate(0deg); /* Начальное положение */
    }
    to {
        transform: rotate(180deg); /* Поворот на 360 градусов */
    }
}