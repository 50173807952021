.filterName {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-blue);
    margin-bottom: 10px;
    cursor: pointer;
}
.filterName.filterGreen {
    color: var(--color-green);
}
.dropdownItemButton {
    position: relative;
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-blue);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}
.dropdownItemButton.alternativeColor {
    color: var(--color-green);
}
.dropdownChevron,
.dropdownItemButton::after {
    margin-left: 3px;
    display: block;
    width: 6px;
    height: 6px;
    background-image: url('../../assets/img/ChevronBlue.svg');
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    transition: all .3s ease-in-out;
    transform: rotate(180deg);
    transform-origin: center;
    content: "";
}
.dropdownChevron,
.dropdownItemButton.alternativeColor::after {
    background-image: url('../../assets/img/ChevronGreen.svg');
}
.activeDropdownChevron,
.dropdownItemButtonActive.dropdownItemButton::after  {
    transform: rotate(0deg) !important;
    margin-top: -2px;
}
.separateLine {
    height: 2px;
    cursor: pointer;
    margin: 7px 0 25px 0;
    background: var(--color-blue);
}

.separateLine.green {
    background: var(--color-green);
}

.dropdownChevron {
    width: 10px;
    height: 10px;
    border: none;
    background-color: transparent;
    margin: 0;
    cursor: pointer;
}
