button.shareButton {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
    cursor: pointer;
}
button.shareButton svg path {
    transition: .3s;
}
button.shareButton:hover svg path {
    fill: var(--color-green-3);
}