.checkbox {
    width: fit-content;
    margin-bottom: 10px;
}
.checkbox:last-child {
    margin-bottom: 0;
}
.checkbox input[type="checkbox"]:checked,
.checkbox input[type="checkbox"]:not(:checked) {
    display: none;
}
.checkbox input[type="checkbox"]:checked + label,
.checkbox input[type="checkbox"]:not(:checked) + label {
    height: 16px;
    display: flex;
    align-items: center;
    position: relative;
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-darkgrey);
    padding-left: 24px;
    cursor: pointer;
}
.checkbox input[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid var(--border-color);
    border-radius: 2px;
}
.checkbox input[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-blue);
    background: var(--color-blue);
}
.checkbox input[type="checkbox"]:checked + label:before,
.checkbox input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
}
.checkbox input[type="checkbox"]:checked + label:after,
.checkbox input[type="checkbox"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.checkbox input[type="checkbox"]:checked + label:after {
    left: 4px;
    top: 5px;
    width: 8px;
    height: 6px;
    background-image: url(../../assets/img/CheckboxArrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
}
.checkbox input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
}

.checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
}
