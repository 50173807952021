.fadeIn {
    -webkit-animation: fadeIn 500ms ease-in; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn 500ms ease-in; /* Firefox < 16 */
    -ms-animation: fadeIn 500ms ease-in; /* Internet Explorer */
    -o-animation: fadeIn 500ms ease-in; /* Opera < 12.1 */
    animation: fadeIn 500ms ease-in;
}
.fadeIn200ms {
    -webkit-animation: fadeIn 200ms ease-in; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn 200ms ease-in; /* Firefox < 16 */
    -ms-animation: fadeIn 200ms ease-in; /* Internet Explorer */
    -o-animation: fadeIn 200ms ease-in; /* Opera < 12.1 */
    animation: fadeIn 200ms ease-in;
}
.fadeIn300ms {
    -webkit-animation: fadeIn 300ms ease-in; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn 300ms ease-in; /* Firefox < 16 */
    -ms-animation: fadeIn 300ms ease-in; /* Internet Explorer */
    -o-animation: fadeIn 300ms ease-in; /* Opera < 12.1 */
    animation: fadeIn 300ms ease-in;
}
@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
