.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-xl);
    width: 100%;
    max-width: 700px;
    border-radius: 16px;
    border: 1px solid var(--Slate-100);
    background: var(--Base-White);
    box-shadow: var(--shadow-sm);
    padding: var(--size-xl) var(--size-sm) var(--size-2xl) var(--size-sm);
    margin: var(--size-lg) auto;
}