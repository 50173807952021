.container {
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--Mint-600);
    text-decoration: none;
    cursor: pointer;
    transition: .3s ease-out;
}
.container:hover {
    color: var(--Mint-500);
}