.container {
    display: flex;
    align-items: center;
    gap: var(--size-lg);
}
.card {
    height: 140px;
    flex: 1 1 auto;
    max-width: 340px;
}
.column {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card:last-child .column {
    justify-content: flex-start;
    gap: var(--size-lg);
}
.column .row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: var(--size-lg);
}
.label {
    color: var(--Slate-500);
}
.input {
    width: 160px;
}
.price {
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
}
.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--size-lg);
}