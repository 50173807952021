.container {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--size-sm);
    padding: 0 24px;
    text-decoration: unset;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--Slate-950);
    border-radius: var(--size-2xs);
    border: 1px solid var(--Slate-200);
    background: var(--Base-White);
    transition: .3s;
}
.container:hover {
    border: 1px solid var(--Slate-500);
}
.container.reverse {
    flex-direction: row-reverse;
}