.selectContainer {
    width: 100%;
    min-height: 32px;
    height: 32px;
    font-family: "ProximaNova-Regular", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-black-grey);
    border: 1px solid var(--select-border-color);
    border-radius: 4px;
    padding: 3px 5px 3px 7px;
    margin-bottom: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../assets/img/Arrow_dropdown.svg');
    background-position:
            calc(100% - 7px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            100% 0;
    background-size:
            7px 4px,
            5px 5px,
            2.5em 2.5em;
    background-repeat: no-repeat;
}
.selectContainer.long {
    width: 100%;
}
.selectContainer.medium {
    width: 100%;
    max-width: 140px;
}
.selectContainer.small {
    width: 100%;
    max-width: 83px;
}
.selectContainer.disabled {
    background: var(--disabled-input-color);
}
.selectContainer.selectWithoutErrors {
    margin-bottom: 25px;
}
.selectContainer.error {
    border: 1px solid #EB5757 !important;
    box-shadow: 0 2px 4px rgba(255, 102, 96, 0.15) !important;
}
