.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
    padding: 10px;
    text-decoration: none;
}
.button.disabledButton {
    background: var(--color-lightgrey);
    border: 1px solid var(--color-lightgrey);
}
.button.disabledButton:hover {
    background: var(--color-lightgrey);
    border: 1px solid var(--color-lightgrey);
    box-shadow: none
}
.transparentButton {
    color: var(--color-green);
    background: var(--color-white);
    border: 1px solid transparent;
}
.transparentButton.buttonGreenText {
    color: var(--color-darkblue);
}
.transparentButton:hover {
    border: 1px solid var(--color-darkgreen);
}
.transparentButton.buttonGreenText:hover {
    border: 1px solid var(--color-darkblue);
}
