.violetButton {
    width: 100%;
    height: 32px;
    color: var(--color-white);
    background: var(--color-violet);
    margin: 0 auto 10px auto;
}
.violetButton:hover {
    box-shadow: 1px 1px 5px rgba(144, 136, 223, .5);
}
