/* DAY OF WEEK SELECT CHECKBOXES */
.sidebarCheckbox .sidebarCheckboxContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 7px;
}
.sidebarCheckboxContainer .sidebarCheckboxItemContainer {
    display: flex;
    flex-direction: column-reverse;
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin-right: 12px;
    align-items: center;
}
.sidebarCheckboxItemContainer p {
    margin-bottom: 4px;
}
/* SIDEBAR CHECKBOX ITEM STYLES */
.sidebarCheckboxItemContainer .sidebarCheckbox input[type="checkbox"]:checked + label,
.sidebarCheckboxItemContainer .sidebarCheckbox input[type="checkbox"]:not(:checked) + label {
    padding-left: 16px;
}
.sidebarCheckboxItemContainer .sidebarCheckbox {
    margin-bottom: 0;
}
