.handlerMonth {
    font-size: 16px;
    line-height: 13px;
    display: flex;
    align-items: center;
}
.handlerMonth button {
    width: 16px;
    height: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    background-image: url("../../assets/img/ChevronBlue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
}
.handlerMonthBtnLeft {
    transform: rotate(-90deg);
}
.handlerMonthBtnRight {
    transform: rotate(90deg);
}
