.container {
    font-family: "ProximaNova-Regular", sans-serif;
    padding: 24px 39px 0 12px;
    background-color: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.18px;
    overflow: auto;
    max-height: calc(100vh - 240px);
}

.container h2 {
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 13px;
}

.totalMetricsHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 62px;
}

.totalMetricsMain {
    display: flex;
    align-items: center;
    margin-bottom: 147px;
}
.totalMetricsTrend {
    width: 280px;
    margin-right: 175px;
}
.totalMetricsTrendCard {
    padding: 16px 22px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.totalMetricsTrendCard + .totalMetricsTrendCard {
    margin-top: 29px;
}
.totalMetricsTrendCard > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}
 .totalMetricsTrendCard > p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    color: var(--color-grey);
    font-weight: 600;
}
.totalMetricsTrendCardCounter {
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 24px;
    line-height: 24px;
}
.totalMetricsTrendCardPercent {
    font-size: 11px;
    line-height: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.totalMetricsTrendCardPercent_up {
     color: var(--color-green);
}
.totalMetricsTrendCardPercent_down {
    color: var(--color-lightred);
}
.totalMetricsTrendCardPercent_up::after,
.totalMetricsTrendCardPercent_down::after {
    content: '';
    background-image: url("../../assets/img/Arrow-icon-up-green.svg");
    width: 11px;
    height: 11px;
    display: block;
    margin-left: 5px;
    position: relative;
    top: -1px;
}
.totalMetricsTrendCardPercent_up::after {
    background-image: url("../../assets/img/Arrow-icon-up-green.svg");
}
.totalMetricsTrendCardPercent_down::after {
    background-image: url("../../assets/img/Arrow-icon-down-red.svg");
}

.totalMetricsConversion {
    display: flex;
    margin: auto;
}
.totalMetricsConversionCircle {
    font-family: "ProximaNova-Bold", sans-serif;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 16px;
    margin-top: 33px;
}
.totalMetricsConversionCircle_lightBlue {
    color: #89DDFF
}
.totalMetricsConversionCircle_blue {
    color: var(--color-blue)
}
.totalMetricsConversionState {
    display: flex;
    flex-direction: column;
    margin: 0 26px;
}
.totalMetricsConversionStatePercent {
    font-family: "ProximaNova-Bold", sans-serif;
    text-align: center;
    color: var(--color-green);
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 18px;
}
.totalMetricsConversionStateBar {
    width: 280px;
    height: 48px;
    background-color: var(--color-lighterblue);
    box-shadow: inset 4px 5px 10px rgba(0, 161, 197, 0.25);
    border-radius: 5px;
    margin-bottom: 40px;
}
.totalMetricsConversionStateBar > div {
    background-color: var(--color-blue);
    box-shadow: inset 2px 3px 3px 1px rgba(0, 143, 175, 0.5);
    border-radius: 5px;
    height: 100%;
}
.totalMetricsConversionState > ul {
    list-style: none;
}
.totalMetricsConversionState > ul > li {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-grey);
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.totalMetricsConversionState > ul > li:last-child {
    margin-bottom: 0;
}
.totalMetricsConversionState > ul > li::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 18px;
    display: block;
}
.totalMetricsConversionState > ul > li:first-child::before {
    background-color: var(--color-green);
}
.totalMetricsConversionState > ul > li:nth-child(2)::before {
    background-color: var(--color-blue);
}
.totalMetricsConversionState > ul > li:nth-child(3)::before {
    background-color: var(--color-lighterblue);
}

.metricsTemplate {
    margin-bottom: 124px;
}
.metricsTemplate > h2 {
    margin-bottom: 10px;
}

.metricsChart {
    margin-bottom: 125px;
}
.metricsChart:last-child {
    margin-bottom: 0;
}
.metricsChartHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.metricsChartHeader > div:first-child {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}
.metricsChartHeaderLegendWrapper {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-darkgrey-two);
}
.metricsChartHeaderLegendWrapper > div {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    background-color: #FAF9F9;
    border-radius: 5px;
}
.metricsChartHeaderLegendWrapper > div + div {
    margin-left: 16px;
}
.metricsChartHeaderLegendCircle {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 6px;
    background-color: black;
}

