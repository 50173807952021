.loader {
    height: 16px;
    width: 16px;
    -webkit-animation: loader-1 4.8s linear infinite;
    animation: loader-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-1 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.loader span {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 16px;
    width: 16px;
    clip: rect(0, 16px, 16px, 8px);
    -webkit-animation: loader-2 1.5s linear infinite;
    animation: loader-2 1.5s linear infinite;
}
@-webkit-keyframes loader-2 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-2 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(220deg); }
}
.loader span::after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 16px;
    width: 16px;
    clip: rect(0, 16px, 16px, 8px);
    border: 2px solid var(--color-green);
    border-radius: 50%;
    -webkit-animation: loader-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    animation: loader-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-3 {
    0%   { -webkit-transform: rotate(-140deg); }
    50%  { -webkit-transform: rotate(-160deg); }
    100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-3 {
    0%   { transform: rotate(-140deg); }
    50%  { transform: rotate(-160deg); }
    100% { transform: rotate(140deg); }
}
