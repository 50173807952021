.container {
    width: 64px;
    height: 64px;
    border-radius: var(--size-sm);
    border: 1px solid var(--Slate-200);
    background: var(--Base-White);
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
}
.container.warning {
    border: 1px solid var(--Red-500);
    box-shadow: 0 0 0 2px #FFE0E2;
}