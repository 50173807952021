.title {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: var(--size-md);
    align-items: center;
}
.text {
    max-width: 300px;
    text-align: center;
}
.form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
}