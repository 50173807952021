.pulse {
    border-radius: 50%;
    background: rgba(144, 136, 223, 1);
    box-shadow: 0 0 0 rgba(144, 136, 223, .4);
    animation: pulse 2s infinite;
}
.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(144, 136, 223, .4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(144, 136, 223, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(144, 136, 223, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(144, 136, 223, .4);
        box-shadow: 0 0 0 0 rgba(144, 136, 223, .4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(144, 136, 223, 0);
        box-shadow: 0 0 0 10px rgba(144, 136, 223, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(144, 136, 223, 0);
        box-shadow: 0 0 0 0 rgba(144, 136, 223, 0);
    }
}
