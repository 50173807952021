.sidebarTimerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sidebarTimerContainer .timePickerInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    width: 70px;
    padding: 7px;
    border: 1px solid var(--select-border-color);
    border-radius: 4px;
}
.timePickerInputContainer .timePickerInput {
    width: 34px;
    min-height: unset;
    height: unset;
    padding: unset;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-black-grey);
    transition: .1s;
    border: 0;
    margin: 0;
}
.timePickerInputContainer .timePickerInput:focus {
    border: none !important;
    box-shadow: none !important;
}
.sidebarTimerContainer p {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin: 0 12px;
}
.sidebarTimeFieldLabel {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin-bottom: 10px;
}
.wrapperTimeField {
    margin-bottom: 25px;
}
