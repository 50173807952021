.action {
    position: relative;
    width: 54px;
    height: 48px;
}
.actionDoubleChain {
    display: flex;
    justify-content: center;
    width: 60px;
}
.actionFunnel {
    width: 76px;
    height: 67px;
}
.actionCanted {
    width: 71px;
    height: 79px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actionOutputDot {
    position: absolute;
    top: 50%;
    right: 0;
    width: 12px;
    height: 12px;
    background: #9088DF;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.actionInputDot {
    position: absolute;
    top: 50%;
    left: 0;
    width: 12px;
    height: 12px;
    background: #9088DF;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.actionCantedInputDot {
    top: 47%;
}
.actionCantedOutputTopDot {
    top: 0;
    left: 50%;
    background: #9088DF;
    transform: translateX(-50%);
}
.actionCantedOutputTopDot.green {
    background: #53D68F;
}
.actionCantedOutputBottomDot {
    top: unset;
    right: unset;
    bottom: 5px;
    left: 50%;
    background: #9088DF;
    transform: translateX(-50%);
}
.actionCantedOutputBottomDot.red {
    background: #FC4A54;
}
.actionCantedOutputRightDot {
    top: 39%;
    right: -5px;
    background: #9088DF;
    transform: translateX(-50%);
}
.actionOutputDot.active,
.actionInputDot.active,
.actionCantedInputDot.active,
.actionCantedOutputTopDot.active,
.actionCantedOutputBottomDot.active,
.actionCantedOutputRightDot.active {
    background: #FFFFFF;
    border: 1px solid #9088DF;
}
.actionInfoTooltip {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    position: absolute;
    left: 75px;
    bottom: -50%;
    height: auto;
    min-height: 105px;
    width: 100%;
    min-width: 150px;
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    border-radius: 2px;
    padding: 10px;
    transition: .2s;
}
.actionInfoTooltip.actionWarningTooltip {
    min-height: 80px;
    min-width: 155px;
    box-shadow: 0 4px 4px rgba(216, 0, 30, 0.15);
    padding: 10px 20px 10px 10px;
}
.actionInfoTooltip.actionWarningTooltip .actionWarningTooltipMessage {
    margin-bottom: 10px;
}
.actionInfoTooltip.actionWarningTooltip .actionWarningTooltipMessageTitle {
    display: block;
    margin-bottom: 3px;
}
.actionInfoTooltip.actionWarningTooltip .closeButton {
    position: absolute;
    right: 5px;
    top: 7px;
    width: 14px;
    height: 14px;
}
.actionInfoTooltip.actionInfoTooltipAddition {
    left: 85px;
}
.actionId,
.actionType {
    margin-bottom: 9px;
}
.actionId span,
.actionType span {
    color: var(--color-darkgrey-two);
}

.actionInfoTooltip button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: none;
    background: var(--color-white);
    color: var(--select-border-color);
    fill: var(--select-border-color);
    margin-bottom: 9px;
    cursor: pointer;
    transition: .3s;
}
.actionInfoTooltip button:last-child {
    margin-bottom: 0;
}
.actionInfoTooltip button:hover {
    color: var(--color-violet);
    fill: var(--color-violet);
}
.actionInfoTooltip button span {
    margin-left: 8px;
}
