.templatesListPage {
    height: calc(100vh - 203px) !important;
}
.templatesEmailFolderTuningSection {
    height: calc(100vh - 203px) !important;
}
.templatesLibraryTuningSection {
    height: calc(100vh - 211px) !important;
}
.searchContainer {
    padding: 20px 16px 25px 16px;
    border-bottom: 1px solid var(--row-color);
}
.checkboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 16px;
    border-bottom: 1px solid var(--row-color);
}
.operatedElementsListContainer {
    border-bottom: 1px solid var(--row-color);
}
.operatedElementsListContainer.emptyElementsList {
    padding-bottom: 32px;
}
.policiesButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 42px 16px;
    border-bottom: 1px solid var(--row-color);
    margin-bottom: 40px;
}
.policiesButtonsContainer .about {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.policiesButtonsContainer .label {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    text-transform: uppercase;
}
.policiesButtonsContainer .description {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    color: var(--color-black);
}
.integrationTuningSection,
.csvUploadTuningSection {
    height: 100%;
}
.csvUploadTuningSectionContainer {
    padding: 35px 20px 20px 20px;
}
.csvUploadTuningSectionNotice {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: var(--color-grey);
    margin: 10px 0;
}
.masterNotesContainer,
.masterUsageContainer {
    padding: 0 16px 32px 16px;
    border-bottom: 1px solid var(--row-color);
}
.masterNotesHeader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 0 0 0;
    margin-bottom: 8px;
}
.masterNotesHeader h6 {
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    text-transform: uppercase;
}
.masterNotesContainer p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black);
}
@media screen and (min-width: 1920px) {
    .csvUploadTuningSectionDropzone {
        width: 100%;
        height: 160px;
    }
}
