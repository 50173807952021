.container {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-lg);
    margin: 0 auto;
    padding: 30px 0;
}
.title {
    text-align: center;
}
.content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
}