.container {
    width: 100%;
    max-width: 600px;
    height: 24px;
    border-radius: 100px;
    border: 1px solid var(--Slate-200);
    background: var(--Slate-100);
    overflow: hidden;
}
.progress {
    height: 100%;
    background-color: var(--Mint-500);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "ProximaNova-Semibold", sans-serif;
    font-size: 14px;
    color: var(--Base-White);
    transition: width 1s ease;
}
.value {
    position: relative;
    z-index: 1;
}