.searchFieldContainer {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}
.searchFieldContainer input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 0;
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-grey);
}
.searchFieldContainer input::placeholder {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: var(--border-color);
}
.searchFieldContainer .searchIcon {
    width: 16px;
    height: 16px;
    background-image: url(../../assets/img/SearchIcon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    margin: 10px 8px;
}

.searchFieldContainer.focus {
    border: 1px solid var(--color-darkblue);
    box-shadow: 0 2px 4px rgba(20, 119, 248, 0.15);
}
