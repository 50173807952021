.label {
    display: inline-block;
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin-bottom: 10px;
}
