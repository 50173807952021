.range-picker-calendar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    z-index: 100;
}
.range-picker-calendar-container {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-white);
    filter: drop-shadow(2px 4px 15px rgba(19, 17, 80, 0.25));
    border-radius: 2px;
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: var(--color-black);
    padding: 16px 20px;
    z-index: 100;
}
.range-picker-calendar-container .nice-dates-navigation {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-black);
    margin: 0 auto 6px auto;
    border-bottom: 1px solid var(--select-border-color);
}
.range-picker-calendar-container .nice-dates-navigation_previous:hover:before,
.range-picker-calendar-container .nice-dates-navigation_next:hover:before {
    border-color: var(--color-black);
}
.range-picker-calendar-container .nice-dates-navigation_next:before {
    margin-left: 10px;
}
.range-picker-calendar-container .nice-dates-navigation_previous:before {
    margin-left: -18px;
}
.range-picker-calendar-container .nice-dates-navigation_previous:before, .nice-dates-navigation_next:before {
    width: 8px;
    height: 8px;
    border-right: 2px solid var(--color-green);
    border-top: 2px solid var(--color-green);
}
.range-picker-calendar-container .nice-dates-week-header {
    margin: 0 auto;
    color: var(--color-grey);
    box-shadow: unset;
}
.range-picker-calendar-container .nice-dates-grid_container {
    margin: 0 auto;
}
.range-picker-calendar-container .nice-dates-day_month {
    display: none;
}
.range-picker-calendar-container .nice-dates-day {
    border: none;
    border-bottom: 1px solid white;
}
.range-picker-calendar-container .nice-dates-day.-outside {
    color: var(--color-lightgrey);
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-middle:not(.-end):nth-child(7n):before {
    border-top-right-radius: 150px;
    border-bottom-right-radius: 150px;
    left: 0;
    right: 12.5%;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-middle:nth-child(7n+1):before {
    border-top-left-radius: 150px;
    border-bottom-left-radius: 150px;
    left: 12.5%;
    right: 0;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-middle:before {
    background-color: rgba(20, 119, 248, .15);
}
.range-picker-calendar-container .nice-dates-day.-selected:not(:hover) * {
    color: var(--color-black);
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-start:not(:hover) * {
    color: var(--color-black);
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-middle:not(:hover) * {
    color: var(--color-black);
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-end:not(:hover) * {
    color: var(--color-black);
}
.range-picker-calendar-container .nice-dates-day.-selected:hover:after {
    background-color: transparent;
    border: transparent;
}
.range-picker-calendar-container .nice-dates-day:after {
    border: 2px solid transparent;
    background-color: transparent;
}
.range-picker-calendar-container .nice-dates-day:hover:after {
    border: 2px solid var(--color-violet);
    background-color: var(--color-violet);
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-start:after,
.range-picker-calendar-container .nice-dates-day.-selected.-selected-middle:after,
.range-picker-calendar-container .nice-dates-day.-selected.-selected-end:after,
.range-picker-calendar-container .nice-dates-day.-selected.-selected-start:hover:after,
.range-picker-calendar-container .nice-dates-day.-selected.-selected-middle:hover:after,
.range-picker-calendar-container .nice-dates-day.-selected.-selected-end:hover:after {
    background-color: transparent;
    border: 2px solid transparent;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-start {
    background-color: transparent;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-end {
    background-color: transparent;
}
.range-picker-calendar-container .nice-dates-week-header_day {
    color: var(--color-violet);
}
.range-picker-calendar-container .nice-dates-day.-selected:before {
    background-color: rgba(20, 119, 248, .15);
    border-radius: 9999px;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-start:before {
    background-color: rgba(20, 119, 248, .15);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-end:before {
    background-color: rgba(20, 119, 248, .15);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-middle:before {
    background-color: rgba(20, 119, 248, .15);
    border-radius: 0;
}
.range-picker-calendar-container .nice-dates-day.-selected.-selected-end:nth-child(7n+1):before {
    border-radius: 9999px;
    left: 12.5%;
}
.range-picker-calendar-container span.nice-dates-day.-selected.-selected-start:hover span,
.range-picker-calendar-container span.nice-dates-day.-selected.-selected-middle:hover span,
.range-picker-calendar-container span.nice-dates-day.-selected.-selected-end:hover span,
.range-picker-calendar-container span.nice-dates-day.-selected:hover span {
    color: var(--color-black);
}
.range-picker-calendar-container span.nice-dates-day:hover {
    color: var(--color-white);
}
.range-picker-calendar-buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid var(--select-border-color);
    border-bottom: 1px solid var(--select-border-color);
    padding: 18px 0;
    margin: 16px 0;
}
.range-picker-calendar-buttons .range-picker-calendar-button {
    width: calc(50% - 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--select-border-color);
    border-radius: 10px;
    color: var(--color-lightgrey);
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    padding: 6px 8px;
    transition: .3s;
    cursor: pointer;
}
.range-picker-calendar-buttons .range-picker-calendar-button:hover {
    color: var(--color-green);
    border: 1px solid var(--color-green);
}
.range-picker-calendar-buttons .range-picker-calendar-button:active {
    color: var(--color-white);
    background: var(--color-green);
    border: 1px solid var(--color-green);
}
.range-picker-calendar-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}
.range-picker-calendar-footer .range-picker-calendar-footer-button {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}
.range-picker-calendar-footer .range-picker-calendar-footer-selected-interval {
    flex: 1;
    color: var(--color-violet);
    text-align: center;
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}


