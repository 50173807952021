.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-xl);
}
.cards {
    position: relative;
    width: 1320px;
    height: 420px;
    overflow: hidden;
}
.card {
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity .5s ease;
}
.card.active {
    opacity: 1;
    z-index: 1;
}
.card.inactive {
    opacity: 0;
    z-index: -1;
}
.indicators {
    width: 80px;
    display: flex;
    align-items: center;
    gap: var(--size-sm);
}

.indicators span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: var(--Slate-300);
    transition: all 0.3s ease-in-out;
}

.indicators .active {
    width: 32px;
    background-color: var(--Slate-950);
    transition: width 0.5s ease-in-out, background-color 0.3s ease-in-out;
}