.container h3 {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: var(--color-black);
    margin-bottom: 16px;
}
.container .error {
    height: 16px;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-red);
    margin-bottom: 10px;
    opacity: 0;
    transition: opacity .3s;
}
.container .error.active {
    opacity: 1;
}
.buttonsContainer {
    display: flex;
    gap: 8px;
    margin-top: 10px;
}
.buttonsContainer .button {
    width: 145px;
}
.dropdown {
    width: 100%;
    max-width: unset;
    margin-bottom: 28px;
}
.dropdown .dropdownListContainer {
    max-width: unset;
}