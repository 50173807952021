.selectCheckboxOptionsHeader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;
}
.selectCheckboxOptionsHeader h6 {
    font-family: "ProximaNova-Bold", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    text-transform: uppercase;
    margin-right: auto;
}
.selectCheckboxOptionsHeader input {
    display: none;
}
.selectCheckboxOptionsHeader p {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-blue);
    text-transform: capitalize;
    margin: 0 5px;
    cursor: pointer;
    transition: .3s;
}
.selectCheckboxOptionsHeader p:hover {
    color: var(--color-grey);
}
