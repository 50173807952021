.gearSwitchableButton {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.gearSwitchableButton svg {
    fill: var(--border-color);
    transition: .3s;
}
.gearSwitchableButton:hover svg {
    fill: var(--color-green);
}
