.container {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    background-color: var(--Slate-100);
    overflow: hidden;
}
.fill {
    height: 100%;
    border-radius: 10px;
    transition: width .5s ease-out;
}
.default {
    width: 0;
    background-color: var(--Slate-100);
}
.weak {
    width: 33%;
    background-color: var(--Red-500);
}
.medium {
    width: 66%;
    background-color: var(--Sunglow-500);
}
.strong {
    width: 100%;
    background-color: var(--Green-500);
}