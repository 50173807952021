.container {
    display: flex;
    justify-content: center;
    gap: 4px;
    padding: 20px 0;
}
.container button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    width: fit-content;
    height: 32px;
    background: var(--color-white);
    border: 1px solid #B9C4E1;
    border-radius: 3px;
    padding: 0 10px;
    fill: var(--border-color);
    font-family: ProximaNova-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-black-grey);
    line-height: 13px;
    transition: .2s;
    cursor: pointer;
}
.container button:first-child,
.container button:last-child {
    background: rgba(239, 237, 255, 0.2);
    fill: var(--color-grey);
}
.container button:hover {
    color: var(--color-violet);
    fill: var(--color-violet);
    border: 1px solid var(--color-violet);
}
.container button:active {
    background: var(--color-transparent-violet);
}
.container button:disabled {
    opacity: .4;
}
.container button:disabled:active {
    background: var(--color-white);
}
.container button:disabled:hover {
    color: var(--color-black-grey);
    fill: var(--border-color);
    border: 1px solid #B9C4E1;
}
.container button:first-child:disabled:hover,
.container button:last-child:disabled:hover {
    background: rgba(239, 237, 255, 0.2);
    fill: var(--color-grey);
}
.container button.selected {
    color: var(--color-black-grey);
    background: var(--color-transparent-violet);
    border: 1px solid var(--color-violet);
}
.container button.dots:disabled {
    border: 1px solid transparent;
    opacity: 1;
}

