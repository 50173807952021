.toast {
    position: relative;
    max-width: 360px;
    min-width: 280px;
    height: 52px;
    display: flex;
    align-items: center;
    background: var(--color-white);
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    animation: bounce .6s;
}
.toast button {
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background: none;
    cursor: pointer;
}
.toast button svg {
    width: 8px;
    height: 8px;
}
.toast button svg path {
    transition: .3s;
}
.toast button:hover svg path {
    stroke: var(--color-lightgrey);
}
.toast .message {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-black);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.toast .message p {
    height: 100%;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    padding: 6px 28px 6px 8px;
}
.toast .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: var(--color-white);
}
.toast .icon svg {
    width: 18px;
    height: 18px;
}
.infoIcon {
    background: #1E90FF;
}
.infoMessage {
    background: rgba(30, 144, 255, .01);
}
.infoMessageTimeline {
    background: rgba(30, 144, 255, .5);
}
.warningIcon {
    background: var(--color-yellow);
}
.warningMessage {
    background: rgba(255, 69, 0, .01);
}
.warningMessageTimeline {
    background: var(--color-yellow);
}
.successIcon {
    background: var(--color-green);
}
.successMessage {
    background: rgba(46, 139, 87, .01);
}
.successMessageTimeline {
    background: rgba(46, 139, 87, .5);
}
.deleteToastAnimation {
    animation: deleteToastAnimation .6s;
}
.timeline {
    width: 100%;
    height: 3px;
    animation: drainAway 3000ms forwards; /* анимация "утекания" */
}
@keyframes bounce {
    0% {transform: translateX(360px);}
    20%, 50%, 80%, 100% {transform: translateX(0);}
    40% {transform: translateX(-30px);}
    60% {transform: translateX(-15px);}
}
@keyframes deleteToastAnimation {
    0% {transform: translateX(-1px);}
    20% {transform: translateX(-3px);}
    40% {transform: translateX(-5px);}
    60% {transform: translateX(-7px);}
    80% {transform: translateX(-10px);}
    100% {transform: translateX(360px);}
}
@keyframes drainAway {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}