.title {
    display: flex;
    flex-direction: column;
    gap: var(--size-md);
    align-items: center;
}
.description {
    display: flex;
    align-items: center;
    gap: var(--size-2xs);
}
.form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
}
.requirements {
    display: flex;
    flex-direction: column;
    gap: var(--size-2xs);
}
.link {
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--Mint-600);
    text-decoration: none;
    cursor: pointer;
    transition: .3s ease-out;
}
.link:hover {
    color: var(--Mint-500);
}