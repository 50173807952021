.title {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: var(--size-md);
    align-items: center;
}
.text {
    text-align: center;
}
.text span {
    font-family: 'ProximaNova-Bold', sans-serif;
    color: var(--Slate-950);
}
.button {
    width: fit-content;
    padding: 0 24px;
}