.input-range {
    margin: 10px 0;
}
.input-range__slider {
    appearance: none;
    background: var(--color-white);
    border: 1px solid var(--select-border-color);
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 24px;
    width: 24px;
    margin-left: -0.5rem;
    margin-top: -0.85rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}
.input-range__slider:active {
    transform: scale(1.1);
}
.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
}
.input-range__slider-container {
    transition: left 0.3s ease-out;
}
.input-range__label {
    color: #aaaaaa;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
}
.input-range__label--min,
.input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
}
.input-range__label--min {
    left: 0;
    display: none;
}
.input-range__label--max {
    right: 0;
    display: none;
}
.input-range__label--value {
    display: none;
    position: absolute;
    top: -1.8rem;
}
.input-range__label-container {
    left: -50%;
    position: relative;
}
.input-range__label--max .input-range__label-container {
    left: 50%;
}
.input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 2px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
    background: #eeeeee;
}
.input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
}
.input-range__track--active {
    background: var(--color-blue);
}
.input-range {
    height: 1rem;
    position: relative;
    width: 100%;
}
