.container {
    display: flex;
    align-items: flex-start;
    gap: var(--size-sm);
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    color: var(--Slate-950);
}
.container.strong {
    font-family: "ProximaNova-Semibold", sans-serif;
}
.container .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
