.container {
    position: relative;
    height: 40px;
    width: 100%;
    max-width: 400px;
    border-radius: var(--size-2xs);
    border: 1px solid var(--Slate-200);
    background: var(--Base-White);
    transition: .3s ease-out;
}
.input {
    width: 100%;
    height: 100%;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--Slate-950);
    padding: var(--size-sm) 38px var(--size-sm) var(--size-md);
    border: 0;
    border-radius: var(--size-2xs);
}
.container:focus-within {
    border: 1px solid var(--Blue-600);
    box-shadow: 0 2px 4px var(--Blue-100);
}
.container::placeholder {
    color: var(--Slate-400);
}
.container.warning {
    border: 1px solid var(--Red-500);
    box-shadow: 0 2px 4px var(--Red-100);
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    border: 0;
    border-radius: 3px;
    background: var(--Base-White);
    cursor: pointer;
}
.button svg path {
    transition: .3s ease-out;
}
.button:hover svg path {
    stroke: var(--Slate-600) !important;
}