.title {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: var(--size-md);
    align-items: center;
}
.text {
    text-align: center;
}
.text span {
    font-family: 'ProximaNova-Bold', sans-serif;
    color: var(--Slate-950);
}
.button {
    width: fit-content;
    padding: 0 24px;
}
.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: var(--Green-100);
}
.circle svg {
    width: 24px;
    height: 24px;
}