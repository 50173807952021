.jointlyInputSelectOptionsContainer {
    display: flex;
    justify-content: space-between;
}
.jointlyInputSelectOptionsContainer select {
    margin-left: 20px;
    margin-bottom: 5px;
}
.selectOptionsSmall {
    max-width: 83px;
}
