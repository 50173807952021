.container {
    display: inline-block;
    position: relative;
}

.dropdown {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--Slate-950);
    border: none;
    background: none;
    cursor: pointer;
}

.arrow {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.menu {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    background-color: var(--Base-White);
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    box-shadow: var(--shadow-sm);
    list-style: none;
    width: 100%;
    z-index: 1;
}

.item {
    padding: var(--size-sm) var(--size-md);
    color: var(--Slate-950);
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
    border-radius: var(--size-2xs);
}

.item:hover {
    background-color: var(--Slate-50);
}
.item:active {
    background-color: var(--Slate-100);
}
