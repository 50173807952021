.tableWrapper {
    padding: 5px 16px;
}
.tableWrapper .rdt_Pagination {
    justify-content: flex-start;
}
.tableWrapper .rdt_TableHeadRow {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--row-color);
}
.tableWrapper .rdt_TableBody {
    min-height: 700px;
}
.tableWrapper .rdt_TableCol_Sortable {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
}

.tableWrapper .rdt_TableCell {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: var(--color-black);
    padding-left: 5px;
    padding-right: 5px;
    transition: .3s;
}

.tableWrapper .rdt_TableRow {
    min-height: 36px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--row-color) !important;
    border-top: 1px solid var(--row-color);
    margin-top: -1px;
}
.tableWrapper .rdt_TableRow:hover {
    background-color: var(--table-row-hover);
    color: rgba(0,0,0,0.87);
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    border-top-color: var(--row-color);
    border-bottom-color: var(--row-color);
    outline-style: solid;
    outline-width: 1px;
    outline-color: var(--color-white);
}
.tableWrapper .rdt_TableCol {
    padding: 0 5px;
}
/*table Wrapper Segments*/
.tableWrapperSegmentsAudience .rdt_TableCell:first-child,
.tableWrapperSegmentsAudience .rdt_TableCol:first-child {
    min-width: 280px;
}

.tableWrapperClientsSegment {
    padding: 0 0 0 16px;
}

.tableWrapperUnitsGroup .rdt_TableCol:nth-child(1) {
    min-width: 200px;
}
.tableWrapperClientsSegment .rdt_TableCell:first-child,
.tableWrapperClientsSegment .rdt_TableCol:first-child {
    width: 100%;
    max-width: 450px;
    min-width: 450px;
}
.tableWrapperClientsSegment .rdt_TableCell:nth-child(5),
.tableWrapperClientsSegment .rdt_TableCol:nth-child(5) {
    width: 50px;
    max-width: 50px;
    min-width: unset;
    padding-right: 0;
}

/*table Wrapper Metrics*/
.tableWrapperMetrics .rdt_TableCol:nth-child(2) {
    width: 100%;
    min-width: 400px;
    max-width: unset;
}
.tableWrapperMetrics .rdt_TableCell:nth-child(2) {
    min-width: 400px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}
.tableWrapperUnitsGroup .rdt_TableCell:nth-child(1) {
    min-width: 200px;
    width: 100%;
}

.tableWrapperRecommendationType .rdt_TableCol:first-child {
    max-width: 35px;
    min-width: 35px;
    width: 100%;
    padding: 0;
}
/*table Wrapper Scenarios*/
.tableWrapperScenarios {
    padding: 5px 16px;
}

.tableWrapperRecommendationType .rdt_TableCol:nth-child(2),
.tableWrapperSlotsUnit .rdt_TableCol:first-child,
.tableWrapperScenarios .rdt_TableCol:first-child {
    max-width: 0;
    min-width: unset;
    width: 100%;
    padding: 0;
}
.tableWrapperRecommendationType .rdt_TableCol:nth-child(3) {
    max-width: 225px;
    min-width: 225px;
    width: 100%;
    padding-left: 1px;
}
.tableWrapperSlotsUnit .rdt_TableCol:nth-child(2) {
    max-width: 170px;
    min-width: 170px;
    width: 100%;
    padding-left: 1px;
}
.tableWrapperSlotsUnit .rdt_TableCol:nth-child(3) {
    max-width: 90px;
    min-width: 90px;
    width: 100%;
    padding: 0
}
.tableWrapperRecommendationType .rdt_TableCol:nth-child(4),
.tableWrapperSlotsUnit .rdt_TableCol:nth-child(4) {
    max-width: 75px;
    min-width: unset;
    width: 100%;
    padding: 0 0 0 8px
}
.tableWrapperScenarios .rdt_TableCol:nth-child(2) {
    max-width: 665px;
    min-width: 665px;
    width: 100%;
    padding-left: 1px;
}
.tableWrapperScenarios .rdt_TableCol:nth-child(3) {
    max-width: 323px;
    padding-left: 11px;
    padding-right: 11px;
}
.tableWrapperScenarios .rdt_TableCol:nth-child(4) {
    max-width: 323px;
    padding-left: 24px;
    padding-right: 18px;
}

.tableWrapperRecommendationType .rdt_TableCell:first-child {
    max-width: 35px;
    min-width: 35px;
    width: 100%;
    padding: 0;
}

.tableWrapperRecommendationType .rdt_TableCell:nth-child(2),
.tableWrapperSlotsUnit .rdt_TableCell:first-child,
.tableWrapperScenarios .rdt_TableCell:first-child {
    max-width: 10px;
    min-width: unset;
    width: 100%;
    padding: 0;
}
.tableWrapperRecommendationType .rdt_TableCell:nth-child(3) {
    max-width: 225px;
    min-width: unset;
    padding-left: 5px;
    padding-right: 5px;
}
.tableWrapperSlotsUnit .rdt_TableCell:nth-child(2) {
    max-width: 170px;
    min-width: unset;
    padding-left: 5px;
    padding-right: 5px;
}
.tableWrapperSlotsUnit .rdt_TableCell:nth-child(3) {
    max-width: 90px;
    min-width: unset;
    padding: 0
}
.tableWrapperRecommendationType .rdt_TableCell:nth-child(4),
.tableWrapperSlotsUnit .rdt_TableCell:nth-child(4) {
    max-width: 75px;
    min-width: unset;
    padding: 0
}
.tableWrapperScenarios .rdt_TableCell:nth-child(2) {
    max-width: 660px;
    min-width: unset;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center
}
.tableWrapperScenarios .rdt_TableCell:nth-child(2) a {
    overflow: hidden;
    text-overflow: ellipsis;
}
.tableWrapperScenarios .rdt_TableCell:nth-child(3) {
    max-width: 332px;
    min-width: unset;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}
.tableWrapperScenarios .rdt_TableCell:nth-child(4) {
    max-width: 320px;
    min-width: unset;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

.tableWrapperScenarios .rdt_TableRow:last-of-type {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--row-color);
}
.tableWrapperFrequencyPolicies .rdt_TableCell:nth-child(1),
.tableWrapperFrequencyPolicies .rdt_TableCol:nth-child(1) {
    min-width: 300px;
}
.tableWrapperFrequencyPolicies .rdt_TableCell:nth-child(3),
.tableWrapperFrequencyPolicies .rdt_TableCol:nth-child(3) {
    min-width: 250px;
}
.tableWrapper.editEventTable .rdt_TableRow:hover {
    background-color: var(--color-white);
}
.tableWrapper.editEventTable .rdt_TableRow > div:first-child > button[aria-disabled="true"] {
    display: none;
}
.tableWrapper.editEventTable .rdt_TableRow > div:first-child > button,
.tableWrapper.editEventTable .rdt_TableRow > div:first-child > button:hover {
    background: transparent;
}
.expandableRowTableWrapper {
    margin-left: 85px;
}
.expandableRowTableWrapper .rdt_TableRow {
    width: 100%;
}
.expandableRowTableWrapper .rdt_TableCell,
.expandableRowTableWrapper .rdt_TableCol {
    flex-grow: 0;
    min-width: 170px;
    max-width: 100%;
}
.clientDashboardTable {
    display: flex;
    flex-direction: column;
    padding: 0;
}
.clientDashboardTable .rdt_TableHead {
    position: relative;
}
.clientDashboardTable .rdt_TableHead:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 52px;
    width: 100%;
    background: linear-gradient(180deg, #89DDFF 0%, rgba(207, 246, 255, .5) 110%);
    opacity: .2;
    content: '';
}
.clientDashboardTable .rdt_TableHeadRow {
    padding: 5px 16px;
    border-bottom-style: none;
    border-bottom-width: 0;
    border-bottom-color: unset;
}
.clientDashboardTable .rdt_TableBody {
    min-height: unset;
    padding: 1px 0;
    max-height: 494px;
    overflow-y: scroll !important;
}
/* width */
.clientDashboardTable .rdt_TableBody::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.clientDashboardTable .rdt_TableBody:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.clientDashboardTable .rdt_TableBody:hover::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
}
/* Track */
.clientDashboardTable .rdt_TableBody::-webkit-scrollbar-track {
    background-color: transparent;
}
/* Handle */
.clientDashboardTable .rdt_TableBody::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
    border-radius: 16px;
}
.clientDashboardTable .rdt_TableBody::-webkit-scrollbar-button {
    display: none;
}
.clientDashboardTable .rdt_TableRow {
    padding: 0 16px;
}
.clientDashboardTable .rdt_TableRow:hover .rdt_TableCell:not(:first-child) {
    color: var(--color-darkblue);
}
.expandableRowTableWrapper .rdt_TableBody {
    min-height: unset;
}
@media screen and (max-width: 1792px) {
    .tableWrapperScenarios .rdt_TableCol:nth-child(2) {
        max-width: 558px;
        min-width: 558px;
    }
}
@media screen and (max-width: 1440px) {
    .tableWrapperScenarios .rdt_TableCol:nth-child(2) {
        padding-left: 0;
        max-width: 280px;
        min-width: 280px;
    }
    .tableWrapperScenarios .rdt_TableCell:nth-child(2) {
        max-width: 280px;
    }

    .tableWrapperScenarios .rdt_TableCol:nth-child(3) {
        padding-left: 13px;
        max-width: 180px;
    }
    .tableWrapperScenarios .rdt_TableCell:nth-child(3) {
        max-width: 180px;
    }
    .tableWrapperScenarios .rdt_TableCol:nth-child(4) {
        padding-left: 18px;
        max-width: 155px;
        margin: 0 auto 0 65px;
    }
    .tableWrapperScenarios .rdt_TableCell:nth-child(4) {
        max-width: 155px;
        margin: 0 auto 0 65px;
    }
    .tableWrapperSegmentsAudience .rdt_TableCell:first-child,
    .tableWrapperSegmentsAudience .rdt_TableCol:first-child {
        min-width: 230px;
    }
    .tableWrapperUnitsGroup .rdt_TableCol:nth-child(1),
    .tableWrapperClientsSegment .rdt_TableCell:first-child,
    .tableWrapperClientsSegment .rdt_TableCol:first-child {
        max-width: 350px;
        min-width: 200px;
    }
}
