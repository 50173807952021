.mobilePushBodyContainer {
    width: 210px;
    min-height: 68px;
    background: rgba(245, 245, 245, .6);
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 5px 5px 1px rgba(0, 0, 0, .15);
}
.mobilePushHeader {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}
.mobilePushHeader span {
    display: block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
    background-image: url('../../../../assets/img/DefaultMobPushLogo.svg');
    background-size: cover;
    background-position: center;
}
.mobilePushAppName {
    flex: 1;
    text-transform: uppercase;
    font-size: 8px;
    line-height: 8px;
}
.mobilePushTime {
    font-size: 10px;
}
.mobilePushTitle {
    font-size: 10px;
    font-weight: 600
}
.mobilePushMsg {
    font-size: 9px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
