.container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: var(--size-sm);
}
.labels {
    display: flex;
    align-items: center;
    gap: var(--size-lg);
    justify-content: space-between;
}