.container[type="checkbox"]:checked,
.container[type="checkbox"]:not(:checked) {
    display: none;
}
.container[type="checkbox"]:checked + label,
.container[type="checkbox"]:not(:checked) + label {
    height: 24px;
    display: flex;
    align-items: center;
    position: relative;
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-darkgrey);
    padding-left: 24px;
    cursor: pointer;
}
.container[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid var(--Slate-400);
    border-radius: 6px;
}
.container[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid var(--Mint-500);
    background: var(--Mint-500);
}
.container[type="checkbox"]:checked + label:before,
.container input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 6px;
}
.container[type="checkbox"]:checked + label:after,
.container[type="checkbox"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.container[type="checkbox"]:checked + label:after {
    left: 5px;
    top: 6px;
    width: 14px;
    height: 12px;
    background-image: url(../../../assets/img/CheckboxArrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
}
.container[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
}

.container[type="checkbox"]:checked + label:after {
    opacity: 1;
}