.container {
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
}
.columns {
    column-count: 3;
    column-width: 320px;
    column-gap: 14px;
    width: 100%;
}
.column {
    display: flex;
    flex-direction: column;
    gap: var(--size-md);
    break-inside: avoid;
}