/* Указываем box sizing и отступы */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}
/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-synthesis: none;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--Black-Haze-50);
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
label,
button,
textarea,
select {
  font: inherit;
  font-synthesis: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
select::-ms-expand {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --bg-color: #F8FAFB;
  --row-color: #F2F2F2;
  --disabled-input-color: #EAEAEA;
  --switch-border-color: #E5E5E5;
  --select-border-color: #E0E0E0;
  --table-row-hover: #FAFCFF;
  --border-color: #BDBDBD;
  --color-white: #ffffff;
  --color-darkgrey-two: #4F4F4F;
  --color-grey: #828282;
  --color-lightgrey: #C4C4C4;
  --color-lightgrey-two: #FAF9F9;
  --color-darkgrey: #333333;
  --color-lighterblue: #B7EAFF;
  --color-lightblue: #D4F7FF;
  --color-thinblue: #ECF4FF;
  --color-blue: #0CBFE7;
  --color-ocean-blue: #00A0C4;
  --color-darkblue: #1477F8;
  --color-green: #00C6A2;
  --color-green-2: #05AC8E;
  --color-green-3: #00DCBD;
  --color-darkgreen: #01B493;
  --color-strong-green: #00a046;
  --color-lightred: #F07178;
  --color-red: #EB5757;
  --color-black-blue: #002831;
  --color-black: #000000;
  --color-black-grey: #1B1B1B;
  --color-yellow: #FFCB6B;
  --color-violet: #9088DF;
  --color-violet-light: #EFEDFF;
  --color-violet-thin: #EFEDFF99;
  --color-transparent-green: #E3FFFA;
  --color-transparent-blue: #E8F8FF;
  --color-transparent-violet: #EFEDFF;

  /* Shopify SignUp Colors */
  --Base-White: #FFF;
  --Base-Black: #000;
  --Slate-50: #F8FAFC;
  --Slate-100: #F1F5F9;
  --Slate-150: #E9EEF5;
  --Slate-200: #E2E8F0;
  --Slate-300: #CBD5E1;
  --Slate-400: #94A3B8;
  --Slate-500: #64748B;
  --Slate-600: #475569;
  --Slate-700: #334155;
  --Slate-800: #1E293B;
  --Slate-900: #0F172A;
  --Slate-950: #020617;
  --Black-Haze-50: #F8FAFB;
  --Black-Haze-100: #EBF1F3;
  --Black-Haze-150: #DFE9ED;
  --Black-Haze-200: #D2DFE5;
  --Black-Haze-300: #ABC5CE;
  --Black-Haze-400: #7EA5B2;
  --Black-Haze-500: #5E8B99;
  --Black-Haze-600: #4A717F;
  --Black-Haze-700: #3C5B68;
  --Black-Haze-800: #354D57;
  --Black-Haze-900: #30434A;
  --Black-Haze-950: #202B31;
  --Mint-50: #EEFFFA;
  --Mint-100: #C6FFF0;
  --Mint-200: #8EFFE3;
  --Mint-300: #4DFBD3;
  --Mint-400: #19E8BF;
  --Mint-500: #00C6A2;
  --Mint-600: #00A48A;
  --Mint-700: #028370;
  --Mint-800: #08675A;
  --Mint-900: #0C554B;
  --Mint-950: #00342F;
  --Green-50: #F0FDF5;
  --Green-100: #DCFCE8;
  --Green-200: #BBF7D1;
  --Green-300: #86EFAD;
  --Green-400: #4ADE80;
  --Green-500: #22C55E;
  --Green-600: #16A34A;
  --Green-700: #15803C;
  --Green-800: #166533;
  --Green-900: #14532B;
  --Green-950: #052E14;
  --Red-50: #FFF1F2;
  --Red-100: #FFE0E2;
  --Red-200: #FFC6C9;
  --Red-300: #FF9FA4;
  --Red-400: #FF6770;
  --Red-500: #FC4A54;
  --Red-600: #EA1824;
  --Red-700: #C5101A;
  --Red-800: #A2121A;
  --Red-900: #86161C;
  --Red-950: #49060A;
  --Sunglow-50: #FFF8EB;
  --Sunglow-100: #FFEBC6;
  --Sunglow-200: #FFCB6B;
  --Sunglow-300: #FFBA4A;
  --Sunglow-400: #FF9F20;
  --Sunglow-500: #F97A07;
  --Sunglow-600: #DD5602;
  --Sunglow-700: #B73806;
  --Sunglow-800: #942A0C;
  --Sunglow-900: #7A240D;
  --Sunglow-950: #461002;
  --Scooter-50: #ECFDFF;
  --Scooter-100: #D0F7FD;
  --Scooter-200: #A7EEFA;
  --Scooter-300: #6AE0F6;
  --Scooter-400: #27C9E9;
  --Scooter-500: #0CBFE7;
  --Scooter-600: #0C89AE;
  --Scooter-700: #116D8D;
  --Scooter-800: #175A73;
  --Scooter-900: #184A61;
  --Scooter-950: #093043;
  --Blue-50: #EEF8FF;
  --Blue-100: #D9EFFF;
  --Blue-200: #BBE3FF;
  --Blue-300: #8CD3FF;
  --Blue-400: #56B8FF;
  --Blue-500: #2F98FF;
  --Blue-600: #1477F8;
  --Blue-700: #1162E4;
  --Blue-800: #144FB9;
  --Blue-900: #174691;
  --Blue-950: #132B58;
  --Chetwode-Blue-50: #F5F6FD;
  --Chetwode-Blue-100: #EDEEFA;
  --Chetwode-Blue-200: #DDDEF7;
  --Chetwode-Blue-300: #C2C3F0;
  --Chetwode-Blue-400: #A29FE6;
  --Chetwode-Blue-500: #9088DF;
  --Chetwode-Blue-600: #6B5ACD;
  --Chetwode-Blue-700: #5C48B9;
  --Chetwode-Blue-800: #4D3C9B;
  --Chetwode-Blue-900: #403280;
  --Chetwode-Blue-950: #261F56;

  /* Shopify SignUp Shadows */
  --shadow-xs: 0px 1px 2px 0px rgba(12, 10, 9, 0.05);
  --shadow-sm: 0px 1px 2px 0px rgba(12, 10, 9, 0.06), 0px 1px 3px 0px rgba(12, 10, 9, 0.10);
  --shadow-md: 0px 4px 8px -2px rgba(12, 10, 9, 0.10), 0px 2px 4px -2px rgba(12, 10, 9, 0.06);
  --shadow-lg: 0px 12px 16px -4px rgba(12, 10, 9, 0.08), 0px 4px 6px -2px rgba(12, 10, 9, 0.03);
  --shadow-xl: 0px 20px 24px -4px rgba(12, 10, 9, 0.08), 0px 8px 8px -4px rgba(12, 10, 9, 0.03);
  --shadow-2xl: 0px 20px 24px -4px rgba(12, 10, 9, 0.08), 0px 8px 8px -4px rgba(12, 10, 9, 0.03);
  --shadow-3xl: 0px 32px 64px -12px rgba(12, 10, 9, 0.14);

  /* Shopify SignUp Flex/Padding/Margin */
  --size-xs: 2px;
  --size-2xs: 4px;
  --size-sm: 8px;
  --size-md: 12px;
  --size-lg: 16px;
  --size-xl: 32px;
  --size-2xl: 64px;
}
@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.otf');
  src: local('ProximaNova-Regular'),
  url('../fonts/ProximaNova-Regular.eot') format('embedded-opentype'),
  url('../fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../fonts/ProximaNova-Semibold.otf');
  src: local('ProximaNova-Semibold'),
  url('../fonts/ProximaNova-Semibold.eot') format('embedded-opentype'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.otf');
  src: local('ProximaNova-Bold'),
  url('../fonts/ProximaNova-Bold.eot') format('embedded-opentype'),
  url('../fonts/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Thin';
  src: url('../fonts/ProximaNova-Thin.otf');
  src: local('ProximaNova-Thin'),
  url('../fonts/ProximaNova-Thin.eot') format('embedded-opentype'),
  url('../fonts/ProximaNova-Thin.woff') format('woff'),
  url('../fonts/ProximaNova-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* width */
body::-webkit-scrollbar {
  display: none;
}
body:hover::-webkit-scrollbar {
  display: none;
}
body:hover::-webkit-scrollbar-thumb {
  display: none;
}
/* Track */
body::-webkit-scrollbar-track {
  display: none;
}
/* Handle */
body::-webkit-scrollbar-thumb {
  display: none;
}
body::-webkit-scrollbar-button {
  display: none;
}
.scenarios-container .scenarios-process-item {
  display: inline-block;
  position: absolute;
}
.app-authorized {
  display: flex;
}
.main-container {
  width: 100%;
  max-width: calc(100vw - 220px);
}

.customGreenScrollbar {
  overflow: auto;
}
/* width */
.customGreenScrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.customGreenScrollbar:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.customGreenScrollbar:hover::-webkit-scrollbar-thumb {
  background-color: var(--color-green);
}
/* Track */
.customGreenScrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
/* Handle */
.customGreenScrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-green);
  border-radius: 16px;
}
.customGreenScrollbar::-webkit-scrollbar-button {
  display: none;
}
@media screen and (max-width: 1365px) {
  .main-container {
    max-width: calc(100vw - 74px);
  }
}
