.sidebarHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.sidebarHeaderContainer h2 {
    margin-bottom: unset;
}
.filterName {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-blue);
    margin-bottom: 10px;
    cursor: pointer;
}
.filterName.filterGreen {
    color: var(--color-green);
}
.filter {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-blue);
    margin-bottom: 25px;
}
.conditionAggregationContainer {
    display: flex;
    flex-direction: column;
}
.filter.filterGreen {
    color: var(--color-green);
}
.filter .filterItemContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.filter .filterItemContainer svg {
    width: 15px;
    height: 16px;
    margin-left: auto;
    cursor: pointer;
}
.filterContainer,
.conditionContainer {
    display: flex;
    flex-direction: column;
}
.radioboxContainer {
    width: 100%;
    max-width: 222px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.sidebarSectionName {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin-bottom: 10px;
}
.timeIntervalRadioboxContainer {
    margin-bottom: 25px;
}
.calendarContainer {
    margin-bottom: 20px;
}
.clientAttributeValuesContainer {
    display: flex;
    flex-direction: column;
}
.intervalInputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 5px 0;
}
.intervalInputContainer .intervalInputLine {
    display: block;
    width: 8px;
    height: 1px;
    background: var(--select-border-color);
    margin-top: 16px;
}
.intervalInputContainer .intervalInputItem {
    display: flex;
    flex-direction: column;
    width: 125px;
}
.percentInputContainer {
    height: 32px;
    max-height: 32px;
    display: flex;
    align-items: center;
    border: 1px solid var(--select-border-color);
    border-radius: 4px;
    margin-bottom: 5px;
    transition: .1s;
}
.percentInputContainer:focus-within {
    border: 1px solid var(--color-darkblue) !important;
    box-shadow: 0 2px 4px rgba(20, 119, 248, 0.15) !important;
    border-radius: 4px;
}
.percentInputContainer p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "ProximaNova-Semibold", sans-serif;
    font-size: 12px;
    line-height: 24px;
    padding: 3px 10px 3px 3px;
}
.percentInputContainer input {
    height: 28px;
    max-height: 28px;
    min-height: 28px;
    width: 100%;
    border: none;
    margin-bottom: unset;
}
.percentInputContainer input:focus {
    border: none !important;
    box-shadow: none !important;
}
.percentInputContainer.error {
    border: 1px solid #EB5757 !important;
    box-shadow: 0 2px 4px rgba(255, 102, 96, .15) !important;
}
.countTimeIntervalContainer {
    display: flex;
    justify-content: space-between;
}
.countTimeIntervalContainer select {
    margin-left: 20px;
    margin-bottom: 5px;
}
.dropDownButtonEmpty {
    margin-bottom: 30px !important;
}
.selectWithoutErrors {
    margin-bottom: 25px !important;
}
.actionSidebarButtons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}
button.segmentButton {
    min-height: 32px;
    height: auto;
    padding: 4px 8px;
}
button.notSelectedSimplifiedActionSidebarButton {
    background: transparent;
    border: 2px solid var(--select-border-color);
    color: var(--select-border-color);
}
button.notSelectedSimplifiedActionSidebarButton:hover {
    box-shadow: 1px 1px 5px rgba(224, 224, 224, .5);
}
button.simplifiedButtonNotSelected {
    border: 1px solid var(--color-red);
}
.label {
    display: inline-block;
    font-family: "ProximaNova-Semibold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-grey);
    margin-bottom: 10px;
}
.simplifiedButtonsContainer {
    margin-bottom: 25px;
}
.conditionSimplifiedContainer {
    margin-bottom: 15px;
}
.selectorsSimplifiedContainer {
    margin-bottom: 25px;
}
.sidebarSearchContainer {
    margin-bottom: 10px;
}
.conditionSimplifiedTimeInterval {
    margin: 15px 0 10px 0;
}
