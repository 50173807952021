.scenariosLeftSideBar {
    width: 100%;
    max-width: 168px;
    height: calc(100vh - 240px);
    min-height: 700px;
    background: var(--color-white);
    margin-right: 24px;
    padding: 4px 0 80px 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
    overflow: auto;
}
.scenariosLeftSideBar .scenariosItemContainer {
    padding: 12px 20px 12px 8px;
    border-bottom: 1px solid var(--row-color);
}
.scenariosItemContainer .scenariosItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    user-select: none;
    cursor: pointer;
}
.scenariosItemContainer .scenariosItem:last-child {
    margin: 0;
}
.scenariosItemContainer .scenariosItem .scenariosItemTitle {
    font-family: "ProximaNova-Regular", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: var(--color-darkgrey-two);
    margin: 0 8px;
    transition: .15s;
}
.scenariosItemContainer .scenariosItem .scenariosItemTitle.purple {
    color: var(--color-violet);
}
.scenariosItemContainer .scenariosItem .scenariosItemTitle.green {
    color: var(--color-green);
}
.scenariosItemContainer .scenariosItem .scenariosItemTitle.blue {
    color: var(--color-blue);
}
.scenariosItemContainer .scenariosItem div {
    cursor: pointer;
}
