.container {
    display: flex;
    align-items: center;
    gap: var(--size-sm);
}
.text {
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--Slate-950);
}