.container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-lg);
    padding: 0 8px;
}
header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
}
nav {
    display: flex;
    align-items: center;
    gap: var(--size-xl);
}
.headerLink {
    font-family: "ProximaNova-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--Slate-950);
    text-decoration: none;
}
.button {
    width: fit-content;
    padding: 0 24px;
}
.about {
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
    max-width: 700px;
}
.pageDescription {
    text-align: center;
}
.pageTitle {
    font-size: 44px;
    text-align: center;
}
.form {
    max-width: 800px;
    background: var(--Blue-100);
    border: 1px solid var(--Blue-100);
}
.content {
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
    max-width: 400px;
}
.title {
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
    align-items: center;
}
.description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-lg);
}
.titleText {
    text-align: center;
}
.namedCheckbox {
    align-items: flex-start;
}